import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { mapGetters, mapState } from 'vuex'
import { abtservice } from 'public/src/services/abt'

const { appLanguage, host } = gbCommonInfo

export default {
  data: () => ({
    allCountries: [],
    mbwayInfo: {
      dialogShow: false,
      code: '',
      phone: '',
      isFocus: false,
      errTip: '',
    },
    blikCode: {
      loaded: false,
      show: false, // 是否展示弹框
      pending: false, // 展示状态是否切换
      loading: false, // 提交loading控制
      error: false, // 验证码异常
      errorText: '', // 错误提示
      jumpUrl: '', // 成功之后跳转链接
      codeList: '',
    },
    // 是否全部为本地采销，本地采销提示语优先
    isAllBusiness: false,
    calCbHasErrTip: false, //防止价格计算接口重复错误提示，true表示价格计算接口的回调cb里有处理错误内容或者提示
    paymentCustomizeFlow: ''
  }),
  computed: {
    ...mapGetters([
      'timePercentAbt',
    ]),
    ...mapState([
      'quickShipInfoTag'
    ]),
    cartsInfo() {
      if (this.nowPageType == 2) {
        return {
          data: {},
          carts: this.checkout?.order?.orderGoodsList,
        }
      } else {
        return this.checkout?.results?.carts
      }
    },
    //是否有店铺商品
    hasStoreGoods() {
      let result = false
      //business_model —— 0：自营；1：店铺
      if (this.nowPageType == 1) {
        result = this.checkout?.results?.carts?.carts?.some(
          (item) => item.business_model == 1
        )
      } else if (this.nowPageType == 2) {
        result = this.checkout?.order?.orderGoodsList?.some(
          (item) => item.business_model == 1
        )
      }
      return result
    },
    //是否全部为店铺商品
    isAllStore() {
      let result = false
      if (this.nowPageType == 1) {
        result = this.checkout?.results?.carts?.carts?.every(
          (item) => item.business_model == 1
        )
      } else if (this.nowPageType == 2) {
        result = this.checkout?.order?.orderGoodsList?.every(
          (item) => item.business_model == 1
        )
      }
      return result
    },
    isShopType() {
      //is_shop:   0：仅自营；1：仅店铺；2：既有店铺也有自营
      let is_shop = 0
      if (this.hasStoreGoods) {
        is_shop = 2
        if (this.isAllStore) {
          is_shop = 1
        }
      }
      return is_shop
    },
    mall_store_infos() {
      let mallStoreInfos = []
      if (this.nowPageType == 2) {
        mallStoreInfos = this.checkout?.order?.mall_list || []
      } else if (this.nowPageType == 1) {
        mallStoreInfos =
          this.checkout?.mall_caculate_info?.mall_store_infos || []
      }
      return mallStoreInfos
    },
    // 获取支付方式列表时的order_details
    // 需过滤虚拟商品
    getPaymentsOrderDetails() {
      let list = []
      if (this.nowPageType == 1) { // 普通一次下单
        list = this.checkout?.results?.carts?.carts || []
      } else if (this.nowPageType == 2) { // 普通二次下单
        list = this.filterVirtualGoodsList(this.checkout?.order) || []
      }
      return this.uniqueBusinessInfo(list.map(v => ({ businessModel: v.business_model, companyId: v.company_id })))
    },
    // 创建session时的order_details
    // 需要加上超省卡/付费会员的业务模式传给后端
    createSessionOrderDetails () {
      let list = []
      if (this.nowPageType == 1) { // 普通｜随单购一次下单
        list = (this.checkout?.results?.carts?.carts || []).map(v => ({ businessModel: v.business_model, companyId: v.company_id }))
        const choosePrimeProductInfo = this.usedPrimeIndex > -1 && this.primeProductList?.length ? this.primeProductList?.[this.usedPrimeIndex] : null
        const chooseXtraProductInfo = this.usedXtraCode && this.xtraProductInfo || null
        if (choosePrimeProductInfo) {
          list.push({ businessModel: choosePrimeProductInfo.business_model })
        }
        if (chooseXtraProductInfo) {
          list.push({ businessModel: chooseXtraProductInfo.business_model })
        }
      } else if (this.nowPageType == 2 || this.nowCheckoutScene === 'XtraAgain' || this.nowCheckoutScene === 'BuyPrimeAgain') { // 普通｜随单购二次下单/超省卡单独购二次下单
        list = (this.checkout?.order?.mall_list || []).map(v => ({ businessModel: v.business_model, companyId: v.company_id }))
      } else if (this.nowCheckoutScene === 'Xtra' || this.nowCheckoutScene === 'BuyPrime') { // 超省卡｜付费会员单独购一次下单
        if (this.primePriceInfo?.business_model != null) {
          list = [{ businessModel: this.primePriceInfo?.business_model }]
        }
      } else if (this.checkout?.results?.order_details_model?.length){
        list = this.checkout?.results.order_details_model
      }
      return this.uniqueBusinessInfo(list)
    }

  },
  methods: {
    getRiskifiedDeviceid() {
      const riskifiedInfo = window.TPM?.runImmediate({
        marketing: 'Riskified',
        method: '_getRiskSessionId',
      })
      if (riskifiedInfo) {
        window.riskifiedDeviceId = riskifiedInfo?.datas || ''
      }
    },
    // quickship文案描述
    quickShipDescribe(item) {
      if (!item.desc_quick_ship_time) return ''
      let _html = item.desc_quick_ship_time || ''

      if (_html) {
        _html = _html.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')
        const quickShipLabelText = this.quickShipInfoTag?.qsDataProps?.text
        quickShipLabelText && (_html = _html.replace(/{QSname}/g, `${quickShipLabelText}`)
          .replace(/\[time\]/g, `${item.quick_ship_time}`))
      }
      return _html
    },
    // 运输文案描述展示判断
    shippingDescribe(item) {
      if (!item) return

      let key = 0,
          _html = ''

      if (item.desc_type == 1) {
        _html = item.desc_expect_time ? item.desc_expect_time.split(/&amp;&amp;|&&/)[key] : ''
        if (this.timePercentAbt) _html += item?.delivery_day_percent_desc || ''
      } else {
        _html = item.desc_shipping_time ? item.desc_shipping_time.split(/&amp;&amp;|&&/)[key] : ''
        if (this.timePercentAbt) _html += item?.shipping_day_percent_desc || ''
      }

      const replaceValue = item.cmmdty_infos?.length ? 
        `<i class="sh_pc_sui_icon_sensitive_16px"></i>` : ''
      _html = _html.replace('{sensitive_icon}', replaceValue)

      if (_html) {
        _html = _html
          .replace(/script/g, '')
          .replace(/\s/g, ' ')
          .replace(/&nbsp;/g, ' ')
        const quickShipLabelText = this.quickShipInfoTag?.qsDataProps?.text
        quickShipLabelText && (_html = _html.replace(/{QSname}/g, `<span style="color:#198055;font-weight: 590;">${quickShipLabelText}</span>`)
          .replace(/\[time\]/g, `<span style="color:#198055">${item.quick_ship_time}</span>`))
      }
      return _html
    },
    filterStorePayments({ paymentMethods = [] }) {
      // let newPayments = paymentMethods.concat([])
      // return newPayments
      return paymentMethods || []
      // const mall_store_infos = this.mall_store_infos
      // this.isAllBusiness = false
      // if (mall_store_infos?.length && newPayments?.length) {
      //   // 判断是否有采销&本地店铺，如果有的话过滤掉该支付方式，如果全部都被过滤了就取消过滤并置灰展示
      //   const isFilterPayment = mall_store_infos.filter(
      //     (item) =>
      //       item.business_model == 1 ||
      //       (item.business_model == 0 && item.company_id == 75)
      //   )
      //   if (isFilterPayment.length) {
      //     const newPaymentMethods = newPayments.filter(
      //       (item) => item.support_platform != 0
      //     )
      //     if (newPaymentMethods.length) {
      //       return newPaymentMethods
      //     } else {
      //       this.isAllBusiness = true
      //       newPayments.forEach((item) => {
      //         item.enabled = 0
      //         item.gray_description = this.language.SHEIN_KEY_PC_21123
      //       })
      //       return newPayments
      //     }
      //   }
      // }

      // //如果没有店铺商品，或者有店铺商品但支付方式都不支持店铺，则返回全部支付
      // if (
      //   !this.hasStoreGoods ||
      //   (this.hasStoreGoods &&
      //     newPayments.every((item) => !+item.support_platform))
      // ) {
      //   return newPayments
      // }
      // // 如果有店铺商品，筛选出支持店铺的支付
      // return newPayments.filter((item) => item.support_platform == 1)
    },
    filterPrimePayments({ paymentMethods = [], isChooseXtra, isChoosePrime }) {
      let newPayments = paymentMethods?.concat?.([]) || []
      newPayments.forEach((item) => {
        // 已经因为其他原因置灰不可用，则不进行后续处理
        if (!item.enabled) return
        // 超省卡优先级 > 付费会员
        if(isChooseXtra) {
          if (item.support_xtra_with_order == 0) {
            item.enabled = 0
            // 其中支付方式置灰文案存在优先级判断， 平台配置不可用（paycenter）＞cod不可用的原因＞付费会员的不可用原因
            item.gray_description = template( item.title, this.language.SHEIN_KEY_PC_27083)
          }
        } else if(isChoosePrime) {
          if (item.support_membership_with_order == 0) {
            item.enabled = 0
            // 其中支付方式置灰文案存在优先级判断， 平台配置不可用（paycenter）＞cod不可用的原因＞付费会员的不可用原因
            item.gray_description = template( item.title, this.language.SHEIN_KEY_PC_23036)
          }
        } 
        if(isChooseXtra && isChoosePrime) {
          if (item.support_membership_with_order == 0 && item.support_xtra_with_order == 0) {
            item.enabled = 0
            // 其中支付方式置灰文案存在优先级判断， 平台配置不可用（paycenter）＞cod不可用的原因＞付费会员的不可用原因
            item.gray_description = template( item.title, this.language.SHEIN_KEY_PC_31536)
          }
        }
      })
      return newPayments
    },
    /**
     * 增加本地自营
     * (1）查车 => 确认业务 => businessTypeCode(自定义)【0跨境自营，无过滤。1有店铺，只取店铺 2无店铺，有本地自营，只取本地自营】
     * (2) poskey => businessModeNewS self(过滤跨境自营) store(过滤店铺) local(过滤本地自营)
     * 1017版本 增加 1P店铺 = 4
     */
    filterPaymentsByBusinessModePayAbt({ paymentMethods = [], formatDetail = '' }){
      let newPayments = paymentMethods?.concat?.([]) || []
      const businessModePayAbtInfo = this.checkout?.checkoutBFFAbtInfo?.['businessModeNewS'] || {}
      const { carts = {} } = this.checkout?.results || {}
      // 普通下单对勾选虚拟商品进行过滤
      let virtualData = []
      if(this.nowPageType == 1 && formatDetail){
        if (typeof formatDetail === 'string') {
          virtualData = formatDetail.split(',').map(v => {
            const [business_model = 0, company_id] = v.split(':')
            return { business_model, company_id }
          })
        }
      }
      try{
        // 下单页首单场景下取购物车接口数据进行过滤
        let mallInfos = [...(carts?.carts || []), ...virtualData]
        // 详情/列表 取orderInfo里的数据
        if ([2, 5].includes(this.nowPageType)) {
          mallInfos = this.checkout?.order?.mall_list || []
        }
        // 二次下单应该先过滤付费会员/超省卡再进行判断，即支付方式列表业务模式判断不处理虚拟商品 => 1p 之后无过滤虚拟商品
        // if (this.nowPageType == 2 || this.nowCheckoutScene === 'XtraAgain') {
        //   mallInfos = this.checkout?.order?.mall_list || []
        //   const primeInfo = (this.checkout?.order?.orderGoodsList || []).find(v => v.is_prime_goods)
        //   if (primeInfo) {
        //     mallInfos = mallInfos.filter(v => v.store_code !== primeInfo.store_code || v.mall_code !== primeInfo.mall_code)
        //   }
        //   // 超省卡
        //   const xtraInfo = (this.checkout?.order?.orderGoodsList || []).find(v => v.is_xtra_goods)
        //   if (xtraInfo) mallInfos = mallInfos.filter(v => v.store_code !== xtraInfo.store_code || v.mall_code !== xtraInfo.mall_code)
        // }
        if(!mallInfos.length) return newPayments
        // 业务模式区别
        const businessType = ({ mallInfos }) => {
          if(mallInfos.some(s => s?.business_model == 1 || s?.business_model == 4)) return 'store'
          if(mallInfos.some(s => s.business_model == 0 && s.company_id == 75)) return 'local'
          return 'self'
        }
        const abtResult = {}
        businessModePayAbtInfo?.param && Object.entries(businessModePayAbtInfo.param).map(item => {
          return abtResult[item[0]] = item[1].split(',')
        })
        // abt过滤，无则不过滤，过滤掉abt参数上的支付方式
        const businessTypeCode = businessType({ mallInfos })
        let abtPayments = newPayments.filter(payment => !abtResult?.[businessTypeCode]?.length || !abtResult?.[businessTypeCode].includes(payment.code))
        // 支付方式至少得有一个可用，否则不过滤
        abtPayments = abtPayments.every(s => s.enabled == 0) ? newPayments : abtPayments
        
        return abtPayments.length ? abtPayments : newPayments
      }catch(e){
        console.log('businessModePayAbt format error', e)
      }
    },
    forterLoad() {
      window.TPM?.run({
        marketing: 'Forter',
        method: '_loadFort',
      })
    },
    async showMbwayDialog() {
      let countryId = this.checkout?.default_address?.countryId
      let allCountries = this.allCountries || []
      //请求所有国家获取电话区号
      if (!allCountries.length) {
        try {
          allCountries = await schttp({
            url: '/api/common/wholeCountryList/get',
          })
        } catch (e) {
          allCountries = []
        }
      }
      let target = allCountries.filter((item) => item.id == countryId)
      target = target[0]

      //展示电话弹窗
      this.mbwayInfo.dialogShow = true
      this.mbwayInfo.phone = this.checkout.default_address.tel
      this.mbwayInfo.code = target.phone_code
    },
    async confirmMbwayPhone() {
      const data = {
        area_abbr: this.checkout.default_address.value,
        alias: this.mbwayInfo.phone,
        area_code: String(parseInt(this.mbwayInfo.code)),
        alias_type: 2,
      }
      this.mbwayConfirmSa && this.mbwayConfirmSa()
      let res = await schttp({
        method: 'POST',
        url: '/api/auth/phoneValidate/get',
        data,
      }).catch(() => {
        return {}
      })
      if (res?.code != 0) {
        this.mbwayInfo.errTip = this.language.SHEIN_KEY_PC_20918
        return
      }
      this.confirmValid = true
      this.mbwayInfo.dialogShow = false
      this.createOrder({ mbwayConfirValid: true })
    },
    mbwayInputFocus() {
      this.mbwayInfo.isFocus = true
      this.mbwayInfo.errTip = ''
    },
    hideBlikCodeCom() {
      this.blikCode.show = false
      let url = ''
      const billno = this.orderDate.billno
      if([1, 2].includes(this.nowPageType)){
        url = host + '/checkout/checkout_again/' + billno
      } else if (['xtra', 'xtra_again'].includes(this.TYPEPAY_PROS)){
        url = host + '/xtra_again/' + billno
      } else if (['buyprime', 'buyprime_again'].includes(this.TYPEPAY_PROS)){
        url = host + '/buyprime_again/' + billno
      } else if(['giftcard', 'giftcard_again'].includes(this.TYPEPAY_PROS)) {
        url = host + '/giftcard/checkout/checkout_again/' + billno
      }
      location.href = url
    },
    submitBlikCodeCom(type = '') {
      this.blikCode.loading = true
      S_Module.require('checkout').handlePay(
        this.orderDate,
        this,
        this.orderDate.jump_pci_token,
        type
      )
    },
    successBlikCodeCom() {
      location.href = this.blikCode.jumpUrl
    },
    sendSubmitDataEvt(errorCode) {
      daEventCenter?.triggerNotice({
        daId: '1-11-1-57',
        extraData: {
          error_code: errorCode || '',
        },
      })
    },
    setPaymentAbtTest (paymentAbtInfo = {}) {
      const abtPaymentValue = paymentAbtInfo?.p?.indexOf?.('paymentoff=') > -1 ? paymentAbtInfo.p.split('=')[1] : ''
      if (abtPaymentValue) {
        const abtPayments = abtPaymentValue.split(',')
        if (abtPayments.length) {
          let abtInfo = abtservice.getUserAbtResultForAnalysis({
            posKeys: 'PaymentABT',
            abtInfo: { PaymentABT: paymentAbtInfo },
          })
          if (SaPageInfo.page_param.abtest) {
            SaPageInfo.page_param.abtest += ',' + abtInfo.sa
          } else {
            SaPageInfo.page_param.abtest = abtInfo.sa
          }
        }
      }
    },
    transformPriceCurrency  (currencyObj = {}) {
      // 没有多语言分隔符 直接返回
      let { separator_multi_resp_list = [] } = currencyObj || {}
      if (!separator_multi_resp_list?.length) return currencyObj || {}

      let existSeparator = separator_multi_resp_list?.filter(item => item?.language_flag == appLanguage)
      if (!existSeparator?.length) return currencyObj || {}

      return {
        ...(currencyObj || {}),
        'dec_point': existSeparator?.[0]?.dec_point_symbol || '',
        'thousands_sep': existSeparator?.[0]?.thousands_sep_symbol || '',
      }
    },
    // 购物车商品业务模式+主体id数据集处理
    // 过滤虚拟商品后的列表 1p之后不过滤虚拟商品
    filterVirtualGoodsList (order = {}) {
      const { mall_list = [], orderGoodsList = [] } = order || {}
      return mall_list
      // 付费会员包/超省卡
      // const virtualGoodsList = (orderGoodsList || []).filter(v => v.is_prime_goods || v.is_xtra_goods) || []
      // const isVirtualGoods = (goodsInfo, virtualMall) => goodsInfo?.store_code === virtualMall?.store_code && goodsInfo?.mall_code === virtualMall?.mall_code
      // return (mall_list || []).filter(v => !virtualGoodsList.some(item => isVirtualGoods(v, item)))
    },
    // 去重
    uniqueBusinessInfo (list = []) {
      // 需去重
      let hashMap = {}
      return list.reduce((pre, cur) => {
        const hash = `${cur.businessModel}:${cur.companyId || ''}`
        if (!hashMap[hash]) {
          hashMap[hash] = true
          return [...pre, cur]
        }
        return pre
      }, [])
    }
  },
}
