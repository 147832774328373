<template>
  <!-- 支付方式 -->
  <div
    id="checkPayment"
    class="check-item check-payment checkout-payment__effiency"
  >
    <div
      class="check-title"
      :class="{ 'animated check-title-animate-shake': shake }"
    >
      <h4
        tabindex="0"
        class="check-title-h4 fsp-element"
        :class="{ 'font-bold': !!IS_RW }"
      >
        {{ language.SHEIN_KEY_PC_14733 }}
      </h4>
      <span
        v-if="status.error.nopayment"
        v-cloak
        id="nopaymentTip"
        ref="noPaymentTip"
        tabindex="0"
        class="payment-unselect"
      >
        ( {{ status.error.txt || language.SHEIN_KEY_PC_14756 }} )
      </span>
      <template v-if="lang == 'ar'">
        <SPopover
          class="cod-reason-popover"
          placemen="bottom"
          :append-to-body="false"
          trigger="hover"
        >
          <template #reference>
            <span 
              v-show="!mall_caculate_info.isSupportCod && mall_caculate_info.notSupportCodPaymentMethodReasonTip"
              class="cod_link"
            >
              {{ language.SHEIN_KEY_PC_14777 }}
            </span>
          </template>
          <div class="cod-reason-inner">
            <template
              v-if="mall_caculate_info.notSupportCodPaymentMethodReasonTip"
            >
              {{ mall_caculate_info.notSupportCodPaymentMethodReasonTip }}
            </template>
            <template v-else>
              {{ language.SHEIN_KEY_PC_14776 }}
            </template>
            <a
              v-show="mall_caculate_info.is_appealed"
              class="cod_link cod_help"
              onclick="showPreRobot()"
            >
              {{ language.SHEIN_KEY_PC_14778 }}
            </a>
          </div>
        </SPopover>
        <span
          v-show="
            mall_caculate_info.is_display ||
              mall_caculate_info.isDisabledCodPayment
          "
        >
          <a
            class="cod_link"
            href="/Cash-on-delivery-a-640.html"
            target="_blank"
          >
            معلومات أكثر عن الدفع عند الاستلام
          </a>
        </span>
      </template>
    </div>

    <div
      class="payment-list"
      :data-render="renderNum"
    >
      <div
        v-show="paymentList.showLoading"
        class="payment-loading not-fsp-element"
      >
        <div class="loading-cover"></div>
        <div class="loading-ball la-ball-pulse la-ball-pulse-black">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <template
        v-for="(item, inx) in paymentMethodsOptions"
        :key="item.code"
      >
        <template
          v-if="
            item.code &&
              item.code.toLowerCase() == 'cod' &&
              item.is_display &&
              (mall_caculate_info.is_display ||
                mall_caculate_info.isDisabledCodPayment)
          "
        >
          <PaymentItemCod
            v-expose="{
              id: calcSaIdClickPayment,
              data: paymentItemAnasysis(item, inx),
            }"
            :status="status"
            :item="item"
            :mall_caculate_info="mall_caculate_info"
            :is-show-view-more="isShowViewMore"
            :inx="inx"
            :checkout="checkout"
            :payment-description-abt="paymentDescriptionAbt"
            :payment-des-tips="getPaymentDescription(item)"
            :language="language"
            :is-show-discount-tips="isShowDiscountTips(item)"
            :is-show-online-pay-discount="isShowOnlinePayDiscount(item)"
            :get-online-pay-discount-value="getOnlinePayDiscountValue(item)"
            :is-show-card-bin-discount="isShowCardBinDiscount(item)"
            :card-bin-discount-desc="cardBinDiscountDesc"
            :random-discount-des="randomDiscountDes"
            :random-discount-abt="randomDiscountAbt"
            :locals="locals"
            @clickPayment="handleClickPayment(item.id, item.code, item, null, {}, inx)"
            @handleCardBinDiscountTips="handleCardBinDiscountTips"
            @codTipExpose="codTipExpose"
            @clickCodTips="clickCodTips"
          />
        </template>
        <template
          v-else-if="
            ['cod'].indexOf((item.code && item.code.toLowerCase()) || '') < 0 &&
              item.is_display
          "
        >
          <PaymentItem
            v-expose="{
              id: calcSaIdClickPayment,
              data: paymentItemAnasysis(item, inx),
            }"
            :item="item"
            :is-show-view-more="isShowViewMore"
            :inx="inx"
            :checkout="checkout"
            :status="status"
            :is-payment-display="checkPaymentDisplay(item.code)"
            :paypal-ga-vault="paypalGaVault"
            :afterpay-vault="afterpayVault"
            :signup-pay-vault="signupPayVault"
            :ppgv-account-info="ppgvAccountInfo"
            :afterpay-account-info="afterpayAccountInfo"
            :signup-account-info="signupAccountInfo"
            :language="language"
            :banks-config="banksConfig"
            :payment-description-abt="paymentDescriptionAbt"
            :payment-des-tips="getPaymentDescription(item)"
            :g-o-o-g-l-e-p-a-y-c-o-d-e="GOOGLEPAY_CODE"
            :google-valid="googleValid"
            :mall_caculate_info="mall_caculate_info"
            :need-pay-inline-payment-methods="needPayInlinePaymentMethods"
            :payment-option="paymentOption"
            :installment-info="installmentInfo"
            :is-show-discount-tips="isShowDiscountTips(item)"
            :is-show-online-pay-discount="isShowOnlinePayDiscount(item)"
            :get-online-pay-discount-value="getOnlinePayDiscountValue(item)"
            :is-show-card-bin-discount="isShowCardBinDiscount(item)"
            :card-bin-discount-desc="cardBinDiscountDesc"
            :pay-limit-tip="getPayLimitTips(item.code)"
            :show-widget-form="showWidgetForm"
            :random-discount-des="randomDiscountDes"
            :random-discount-abt="randomDiscountAbt"
            :handle-start-select-bank="handleStartSelectBank"
            :is-pay-methods-dialog="isPayMethodsDialog"
            :is-auto-renewal="isAutoRenewal"
            :is-disabled-pay="isDisabledPay(item)"
            :locals="locals"
            :default-select-token-pay="defaultSelectTokenPay"
            @clickPayment="handleClickPayment(item.id, item.code, item, null, {}, inx)"
            @clickPaypalGaType="clickPaypalGaType"
            @toggle-ppgv-type="togglePPGVType"
            @toggle-afterpay-type="toggleAfterpayType"
            @toggle-signup-pay-type="toggleSignupPayType"
            @handleCardBinDiscountTips="handleCardBinDiscountTips"
            @changeDefaultSelectTokenPay="changeDefaultSelectTokenPay"
            @handler-tips="handlerTips"
          />
        </template>
      </template>
    </div>
    <div
      v-if="isShowViewMore"
      v-expose="{
        id: '1-11-1-131',
        data: {
          payment_method: 'view_more',
          is_vaulting: '-',
          is_folded: 0,
          is_pay_promotion: 0,
          is_default: '-',
          default_type: '-',
          sequence: checkout?.results?.originFoldedPosition + 1
        },
      }"
      class="check-payment__more"
      @click.stop="toUnfold"
    >
      <span class="check-fold-text">{{ language.SHEIN_KEY_PC_22484 }}</span>
      <ul
        v-if="isFoldWithIcon && foldIconList.length > 0"
        ref="foldIcon"
        class="check-fold-icon"
        :style="{ width: foldIconStyle.width ? `${foldIconStyle.width}px` : 'auto' }"
      >
        <li
          v-for="(icon, index) in foldIconList"
          :key="icon"
          class="check-fold-icon-item"
          :style="`width: ${FOLD_ICON_ITEM_STYLE.width}px; ${GB_cssRight ? 'margin-right' : 'margin-left'}: ${index === 0 ? 0 : FOLD_ICON_ITEM_STYLE.marginLeft}px;`"
        >
          <img :src="transformImg({ img: icon })" />
        </li>
        <li
          v-if="isShowFoldMore"
          class="check-fold-icon-item check-fold-icon-more"
          :style="`width: ${FOLD_ICON_ITEM_STYLE.width}px; ${GB_cssRight ? 'margin-right' : 'margin-left'}: ${index === 0 ? 0 : FOLD_ICON_ITEM_STYLE.marginLeft}px;`"
        >
          <Icon
            name="sui_icon_more_24px"
            size="24px"
          />
        </li>
      </ul>
      <Icon
        name="sui_icon_more_down_14px_1"
        color="#2d68a8"
        size="14px"
      />
    </div>
  </div>
</template>

<script>
/*
 * 支付方式组件。使用范围普通订单下单页、二次下单页，礼品卡下单页、二次下单页，付费会员单独购页
 *
 * */
// import slideUpDown from '@sheinfe/vue-slide-up-down'
// import selectVue from '../../components/address/select.vue'
import PaymentItem from './paymentItem'
import PaymentItemCod from './paymentItemCod'
// import CheckPpgaType from '../components/CheckPpgaType.vue'
// const { GB_cssRight, lang, IS_RW } = gbCommonInfo
import {
  transformImg,
  template as _commonTemplate,
  isNumber,
} from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { windowScrollInstance } from 'public/src/services/expose/index.js'
import GooglePay from 'public/src/pages/checkout/components/GooglePay/googlePay'
import { PPGA_CHECK_TYPE } from 'public/src/pages/common/constants'
import { INDEPENDENT_PCI_METHODS } from 'public/src/pages/common/handlePay/config.js'
import { mapActions, mapState } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import schttp from 'public/src/services/schttp'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const GOOGLEPAY_CODE = GooglePay.googlePayMethods

// const paymentRandomShowGet = () => {
//   const { checkout } = window?.gbCoSsrData || window?.gbCoAgainSsrData || {}
//   return (
//     checkout?.checkoutBFFAbtInfo?.PaymentRandomDiscount?.param
//       ?.PaymentRandomShow == 'Show'
//   )
// }

const FOLD_ICON_ITEM_STYLE = {
  width: 36,
  marginLeft: 4
}

export default {
  name: 'PaymentOptions',
  components: {
    // slideUpDown,
    // selectVue,
    PaymentItem,
    PaymentItemCod,
    Icon,
    SPopover,
    // CheckPpgaType
  },
  props: {
    language: {
      type: Object,
      default: function () {
        return {}
      },
    },
    status: {
      type: Object,
      default: function () {
        return {}
      },
    },
    checkout: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paymentList: {
      type: Object,
      default: function () {
        return {}
      },
    },
    banksConfig: {
      type: Object,
      default: function () {
        return {}
      },
    },
    installmentInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paypalGaVault: Object,
    afterpayVault: Object,
    signupPayVault: Object,
    ppgvAccountInfo: Object,
    afterpayAccountInfo: Object,
    signupAccountInfo: Object,
    paymentDescriptionAbt: {
      type: Boolean,
      default: false,
    },
    googleValid: {
      type: Boolean,
      default: false,
    },
    shake: {
      type: Boolean,
      default: false,
    },
    showWidgetForm: {
      type: Number,
      default: 0,
    },
    needPayInlinePaymentMethods: {
      type: Array,
      default: function () {
        return []
      },
    },
    pageName: {
      type: String,
      default: '',
    },
    handleStartSelectBank: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    productType: {
      type: String,
      default: 'normal',
    },
    isPayMethodsDialog: {
      type: Boolean,
      default: false,
    },
    paymentOption: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isAutoRenewal: {
      type: Boolean,
      default: false
    },
    paymentDisplayFunc: Function,
    paymentDesFunc: Function,
    payLimitTipShowFunc: Function,
    locals: {
      type: Object,
      default: function () {
        return {}
      }
    },
    autoType: {
      type: String,
      default: 'prime'
    },
    coupon: {
      type: Object,
      default: function () {
        return {}
      }
    },
    couponBinList: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
      // IS_RW,
      // lang,
      // GB_cssRight,
      renderNum: 1,
      GOOGLEPAY_CODE,
      // isFoldPayment: false,
      // randomDiscountAbt: paymentRandomShowGet(),
      PPGA_CHECK_TYPE,
      couponList: [],
      isShowFoldMore: false,
      foldIconStyle: {},
      FOLD_ICON_ITEM_STYLE,
    }
  },
  computed: {
    ...mapState([
      'usableCouponList',
    ]),
    // ...mapState(['defaultSelectTokenPay']),
    defaultSelectTokenPay() {
      if(this?.$store?.state) { // 检查模块是否存在
        return this?.$store.state?.defaultSelectTokenPay
      }
      return {}
    },
    mall_caculate_info() {
      return (
        this.checkout.mall_caculate_info || this.checkout.caculate_info || {}
      )
    },
    paymentMethodLimitInfo() {
      return (
        this.checkout.mall_caculate_info?.paymentMethodLimitInfo ||
        this.checkout.caculate_info?.paymentMethodLimitInfo ||
        {}
      )
    },
    paymentMethodsOptions() {
      const { paymentMethods = [], primeProductInfo = {}, xtraProductInfo } =
        this.checkout?.results || {}

      const autoInfo = this.autoType == 'xtra' ? xtraProductInfo : primeProductInfo

      if (!this.isPayMethodsDialog) {
        return paymentMethods
      }

      const limitedPayTypeList = autoInfo?.limitedPayTypeList?.map(
        i => i?.code
      )
      return paymentMethods.slice().filter(item => {
        const isLimited =
          limitedPayTypeList.includes(item.code) && item?.enabled === 1
        if (item.code === 'PayPal-GApaypal') {
          const isShow =
            !this.isDisabledPayment(item) &&
            this.paypalGaVault &&
            this.paypalGaVault.valid &&
            this.paypalGaVault.express

          const other =
            this.paypalGaVault.valid &&
            item.enabled == 1 &&
            !this.status?.disabled?.[item.code] &&
            this.needPayInlinePaymentMethods.indexOf(item.code) > -1 &&
            this.paypalGaVault.visibleArrow

          return isLimited && (isShow || other)
        } else if(item.code === 'afterpay-card'){
          const switchVal = this.afterpayVault?.switch
          const other = item.enabled == 1 &&
            !this.status?.disabled?.[item.code]
          return isLimited && switchVal && other
        }
        return isLimited
      })
    },
    checkoutPaymentMethods() {
      const { folded_position } = this.checkout.results || {}
      const paymentMethods = this.paymentMethodsOptions
      const isUnfold =
        !this.isFoldPayment ||
        !isNumber(folded_position) ||
        folded_position <= 0
      if (isUnfold) {
        return paymentMethods
      }
      return paymentMethods.filter((_, index) => index < folded_position)
    },
    isShowViewMore() {
      return (
        this.checkoutPaymentMethods?.length !==
        this.paymentMethodsOptions.length
      )
    },
    // 卡bin优惠信息
    cardBinDiscountDesc() {
      // 当命中多个卡bin优惠，不区分命中的类型
      let binDiscountLen =
        this.checkout?.results?.bin_discount_list?.length || 0
      if (binDiscountLen > 1) {
        binDiscountLen = binDiscountLen > 3 ? 3 : binDiscountLen
        const lengthObj = {
          2: this.language?.SHEIN_KEY_PC_27086,
          3: this.language?.SHEIN_KEY_PC_27087,
        }

        return _commonTemplate(
          ...this.checkout?.results?.bin_discount_list?.map(
            item => item?.bank_name
          ),
          lengthObj[binDiscountLen]
        )
      }

      const {
        bank_name = '',
        discount_amount = {},
        discount_percentage = 0,
        discount_type = 0,
        max_discount_amount = {},
        threshold_amount = {},
        max_random_amount = {},
        min_random_amount = {},
      } = this.checkout?.results?.bin_discount_list?.[0] || {}
      // 满减
      if (discount_type === 1) {
        return _commonTemplate(
          discount_amount?.amountWithSymbol,
          threshold_amount?.amountWithSymbol,
          bank_name || '',
          this.language?.SHEIN_KEY_PC_25745
        )
      }
      // 折扣
      if (discount_type === 2) {
        const maxDiscountAmount = max_discount_amount?.amount
        const thresholdAmount = threshold_amount?.amount
        // 折扣--无门槛
        if (thresholdAmount == 0) {
          return _commonTemplate(
            maxDiscountAmount > 0
              ? max_discount_amount.amountWithSymbol
              : discount_percentage || '',
            bank_name || '',
            maxDiscountAmount > 0
              ? this.language?.SHEIN_KEY_PC_25812
              : this.language?.SHEIN_KEY_PC_25813
          )
        }
        return _commonTemplate(
          maxDiscountAmount > 0
            ? max_discount_amount.amountWithSymbol
            : discount_percentage || '',
          bank_name || '',
          threshold_amount?.amountWithSymbol,
          maxDiscountAmount > 0
            ? this.language?.SHEIN_KEY_PC_25751
            : this.language?.SHEIN_KEY_PC_25746
        )
      }
      // 随机立减
      if (discount_type == 3 && this.isShowBinRandomDiscountABT) {
        const thresholdAmount = threshold_amount?.amount
        // 折扣--无门槛
        if (thresholdAmount == 0) {
          return _commonTemplate(
            min_random_amount?.amountWithSymbol,
            max_random_amount?.amountWithSymbol,
            bank_name || '',
            bank_name
              ? this.language?.SHEIN_KEY_PC_28784
              : this.language?.SHEIN_KEY_PC_26118
          )
        }
        return _commonTemplate(
          min_random_amount?.amountWithSymbol,
          max_random_amount?.amountWithSymbol,
          threshold_amount?.amountWithSymbol,
          bank_name || '',
          bank_name
            ? this.language?.SHEIN_KEY_PC_28783
            : this.language?.SHEIN_KEY_PC_25745
        )
      }
      return ''
    },
    isShowCardBinDiscountABT() {
      const { BinDiscountdiscount = {} } =
        this.checkout?.checkoutBFFAbtInfo || {}
      return BinDiscountdiscount?.param?.BinDiscountShow === 'Show'
    },
    // 卡bin随机立减展示
    isShowBinRandomDiscountABT() {
      const { BinRandomDiscount = {} } = this.checkout?.checkoutBFFAbtInfo || {}
      return BinRandomDiscount?.param?.BinRandomShow === 'Show'
    },
    isFoldPayment() {
      let isFold = false
      if (this.pageName !== 'checkout') return isFold

      isFold =
        this.checkout.checkoutBFFAbtInfo?.foldpayment?.param === 'fold_payment'
      return isFold
    },
    isFoldWithIcon() {
      const { foldPaymentLogo = {} } = this.checkout?.checkoutBFFAbtInfo || {}
      return foldPaymentLogo?.param?.foldPaymentLogoStatus == 1
    },
    foldIconList() {
      let iconList = []

      if (this.isFoldWithIcon) {
        // 支付icon折叠展示，产品说不会有重复的logo，按最简单的逻辑处理 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1377258399
        const { folded_position } =
        this.checkout?.results || {}
        const paymentMethods = this.paymentMethodsOptions

        iconList = paymentMethods.reduce(
          (pre, next, index) => {
            if (index >= folded_position) {
              if (INDEPENDENT_PCI_METHODS.includes(next.code) && next.card_logo_list?.length) {
                // 卡支付方式且卡组logo不为空, 取卡组logo前三个icon
                const cardIcons = next.card_logo_list.slice(0, 3)
                pre = pre.concat(cardIcons)
              } else {
                pre.push(next.logo_url)
              }
            }
            return pre
          }, []
        )
      }
      return iconList
    },
    calcSaIdClickPayment() {
      if (this.productType === 'giftcard') {
        return '1-50-1-3'
      }
      return '1-11-1-131'
    },

    // 是否有卡 bin 优惠券
    isHadBinCoupons() {
      return this.couponList?.some(item => item?.card?.card_codes?.length > 0)
    },
    randomDiscountAbt () {
      return this.checkout?.checkoutBFFAbtInfo?.PaymentRandomDiscount?.param?.PaymentRandomShow == 'Show'
    },
    GB_cssRight () {
      return typeof window !== 'undefined' ? window.gbCommonInfo?.GB_cssRight : this.locals?.GB_cssRight
    },
    lang () {
      return typeof window !== 'undefined' ? window.gbCommonInfo?.lang || this.locals?.lang : this.locals?.lang
    },
    IS_RW () {
      if (this.locals?.IS_RW) return this.locals?.IS_RW
      return typeof window !== 'undefined' ? window.gbCommonInfo?.IS_RW : false
    },
    routePayCardBinList() {
      return ((this.paymentMethodsOptions || []).find(item => item.code === 'routepay-card')?.card_token_list || [])?.map(item => item?.card_bin) || []
    }
  },
  watch: {
    // isShowViewMore: {
    //   deep: true,
    //   immediate: true,

    //   handler(n) {
    //     if (n) {
    //       daEventCenter.triggerNotice({ daId: '1-11-1-72' })
    //     }
    //   },
    // },
    installmentInfo: {
      immediate: true,
      deep: true,
      handler() {
        if (typeof window == 'undefined') return
        this.$nextTick(() => {
          this.initExposeEvent()
        })
      },
    },
    isShowCardBinDiscountABT: {
      immediate: true,
      handler() {
        if (typeof window == 'undefined') return
        if (!this.isShowCardBinDiscountABT) return
        const extraData = {}
        let temp_match_payments = []
        let is_discount_type = false
        let discountTypeList = []
        let discount_type_key_list = []
        this.checkout?.results?.bin_discount_list?.forEach(item => {
          if (item?.discount_type != 0) {
            is_discount_type = true

            if (!discount_type_key_list.includes(item.discount_type)) {
              discount_type_key_list.push(item.discount_type)
              let temp_payment_list = this.paymentMethodsOptions
                .filter(now_item =>
                  item?.match_payments.includes(now_item.code)
                )
                .map(temp_item => temp_item.code)
                .join('`')

              temp_match_payments = [...temp_match_payments, temp_payment_list]
              discountTypeList.push(
                `${item.discount_type}:${temp_payment_list}`
              )
            }
          }
        })

        extraData.is_full = is_discount_type ? 1 : 0

        if (is_discount_type) {
          extraData.payment_list = temp_match_payments.join('`')

          daEventCenter.triggerNotice({
            daId: '1-11-1-117',
            extraData: {
              discountType: discountTypeList.join(','),
            },
          })
        }

        daEventCenter.triggerNotice({
          daId: '1-11-1-116',
          extraData,
        })
      },
    },

    'checkout.order': {
      handler() {
        if (this.checkout?.order?.coupon_list) {
          this.getData()
        }
      },
      immediate: true,
      deep: true,
    },

    'usableCouponList': {
      deep: true,
      handler() {
        if (typeof window === 'undefined') return
        this.$nextTick(() => {
          this.validateBin()
        })
      }
    },
  },
  created() {
    // this.getAbtInfo()
  },
  mounted() {
    this.anglesTrigger()
    //用于改变变量重新渲染dom，避免chrome记录下单页的支付编码input选项
    this.$nextTick(() => {
      this.renderNum++
      this.setFoldIconWidth()
    })
  },
  methods: {
    ...mapActions(['handleSelectTokenPay']),
    paymentItemAnasysis(item, idx) {
      let is_vaulting = '-'
      if (item.code == 'PayPal-GApaypal') {
        is_vaulting = !!this.ppgvAccountInfo?.signUpEmail ? '1' : '0'
      }

      const { folded_position, originFoldedPosition } = this.checkout.results || {}
      // is_folded：是否折叠 若加载结算页后默认展示的支付方式被曝光，则上报0
      // 若是点击「View More」之后才被曝光的支付方式，则上报1。
      const is_folded = (folded_position == -1 && idx >= originFoldedPosition) ? 1 : 0
      // 0：无支付方式优惠; 1：有支付方式优惠
      const is_pay_promotion = this.mall_caculate_info?.onlinePayDiscountInfo
        ?.onlinePayDiscountValue?.[item.code] ? 1 : 0
      // 0:没有默认选中;1:默认选中
      const is_default = (this.status?.cardPay == item?.code && item?.enabled == 1 && item?.last_payment) ? 1 : 0
      // 0：无默认选中埋;1: 接口返回;2：cache 缓存
      const default_type = item?.last_payment ? 1 : 0
      // 展示顺序
      const sequence = idx + 1
      return { payment_method: item.code, is_vaulting, is_folded, is_pay_promotion, is_default, default_type, sequence }
    },
    initExposeEvent() {
      windowScrollInstance.observe(
        {
          section: [
            {
              code: 'paymentOptionsExposeCode',
              container: '#checkPayment',
              content: '.payment-list',
              target: '.extra-tip',
              averageContent: false,
            },
          ],
        },
        ({ exposeDoms: targets }) => {
          targets?.forEach(dom => {
            const payment_code = dom.getAttribute('data-payment-code') || ''
            if (payment_code) {
              daEventCenter.triggerNotice({
                daId: '1-11-1-87',
                extraData: {
                  payment_code: payment_code,
                },
              })
            }
          })
        }
      )
    },
    toUnfold() {
      // daEventCenter.triggerNotice({ daId: '1-11-1-71' })
      daEventCenter.triggerNotice({
        daId: '1-11-1-132',
        extraData: {
          payment_method: 'view_more',
          is_vaulting: '-',
          is_folded: 0, 
          is_pay_promotion: 0, 
          is_default: '-', 
          default_type: '-', 
          sequence: this.checkout?.results?.originFoldedPosition + 1
        },
      })
      this.$emit('unfold')
    },
    // async getAbtInfo() {
    //   if (this.pageName !== 'checkout') {
    //     return
    //   }
    //   const abtInfo = await abtservice.getUserAbtResult({
    //     posKeys: 'foldpayment',
    //   })
    //   this.isFoldPayment = abtInfo?.foldpayment?.param === 'fold_payment'
    // },
    clickPaypalGaType() {
      this.$emit('show-paypalga-type', true)
    },
    togglePPGVType() {
      this.$emit('toggle-ppgv-type')
    },
    toggleAfterpayType() {
      console.log('paymentOptions toggleAfterpayType')
      this.$emit('toggle-afterpay-type')
    },
    toggleSignupPayType(paymentCode) {
      this.$emit('toggle-signup-pay-type', paymentCode)
    },
    getOnlinePayDiscountValue(item) {
      return (
        this.mall_caculate_info?.onlinePayDiscountInfo
          ?.onlinePayDiscountValue?.[item.code] || {}
      )
    },
    // 当前币种判断
    currentSupport(item) {
      const currency = gbCommonInfo.currency
      const currencySupportObj =
        this.checkout.results?.currencySupport?.find(
          f => f.payMethod == item.code
        ) || {}
      return currencySupportObj.defaultCurrency === currency
    },
    // 置灰判断
    isDislabel(item) {
      const currentPaymentDisble =
        this.paymentMethodLimitInfo?.[item.code] === false ? false : true
      const currentSupport = this.currentSupport(item)
      return currentPaymentDisble && currentSupport
    },
    // 有门槛/最高折扣金额展示
    isShowOnlinePayDiscount(item) {
      const onlinePayDiscountValue = this.getOnlinePayDiscountValue(item) || {}
      const isRandomDiscount = this.isShowOnlineRandomDiscount(item)
      return (
        item.enabled &&
        !!Object.keys(onlinePayDiscountValue)?.length &&
        ((onlinePayDiscountValue.discountType != 2 &&
          onlinePayDiscountValue?.min &&
          onlinePayDiscountValue?.min?.amount != 0) ||
          (onlinePayDiscountValue.discountType == 1 &&
            onlinePayDiscountValue?.limit_value &&
            onlinePayDiscountValue?.limit_value?.amount != 0) ||
          isRandomDiscount)
      )
    },
    // 当前支付方式是否展示在线支付随机立减优惠
    isShowOnlineRandomDiscount(item) {
      const onlinePayDiscountValue = this.getOnlinePayDiscountValue(item) || {}
      const isRandomDiscount =
        onlinePayDiscountValue.discountType == 2 &&
        +onlinePayDiscountValue?.random_max?.amount != 0 &&
        this.randomDiscountAbt
      return item.enabled && isRandomDiscount
    },
    // 随机立减文案 - 在线支付优惠
    randomDiscountDes(item) {
      const onlinePayDiscountValue = this.getOnlinePayDiscountValue(item) || {}
      const min = onlinePayDiscountValue?.min
      const randomMin =
        onlinePayDiscountValue?.random_min?.amountWithSymbol ||
        onlinePayDiscountValue?.randomMin?.amountWithSymbol ||
        ''
      const randomMax =
        onlinePayDiscountValue?.random_max?.amountWithSymbol ||
        onlinePayDiscountValue?.randomMax?.amountWithSymbol ||
        ''
      const result = !+min.amount
        ? _commonTemplate(
          randomMin,
          randomMax,
          this.language.SHEIN_KEY_PC_26118
        )
        : _commonTemplate(
          randomMin,
          randomMax,
          min.amountWithSymbol,
          this.language.SHEIN_KEY_PC_26181
        )
      return result
    },
    // 支付方式全量上报
    anglesTrigger() {
      const pament_onLinePay = Object.entries(
        this.mall_caculate_info?.onlinePayDiscountInfo
          ?.onlinePayDiscountValue || {}
      ).map(r => {
        return {
          ...r[1],
          paymentCode: r[0],
        }
      })
      // 判断是否存在卡bin随机立减的配置数据
      const matchBinRandomDiscountQualificationPayments = () => {
        const binDiscountList = this.checkout?.results?.bin_discount_list || []
        if (binDiscountList.length > 1) return []
        const { discount_type = 0, match_payments = [] } =
          binDiscountList?.[0] || {}
        return discount_type === 3 ? match_payments : []
      }

      // 符合随机立减 不受abt影响
      const qualificationsPay = () => {
        // 一个符合随机立减 即为1，否则为0
        const full_result = pament_onLinePay.some(s => s.discountType == 2)
          ? 1
          : 0
        const payment_list_result = pament_onLinePay
          .filter(f => f.discountType == 2)
          .reduce(
            (pre, next) => String(pre) + String(next.paymentCode) + '`',
            ''
          )
        daEventCenter.triggerNotice({
          daId: '1-11-1-143',
          extraData: {
            is_full: full_result,
            payment_list: payment_list_result,
          },
        })
      }
      // 随机立减文案，受abt影响
      const discountText = () => {
        const payment_list_result = pament_onLinePay
          .filter(f => f.discountType == 2)
          .reduce(
            (pre, next) => String(pre) + String(next.paymentCode) + '`',
            ''
          )
        daEventCenter.triggerNotice({
          daId: '1-11-1-144',
          extraData: {
            payment_list: payment_list_result,
          },
        })
      }

      const matchBinRandomDiscountPaymentsResult =
        matchBinRandomDiscountQualificationPayments()
      // 随机立减的曝光上报，目前不区分在线支付随机立减和卡bin随机立减
      if (pament_onLinePay.length) {
        qualificationsPay()
        this.randomDiscountAbt && discountText()
      } else if (matchBinRandomDiscountPaymentsResult.length) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-143',
          extraData: {
            is_full: 1,
            payment_list: matchBinRandomDiscountPaymentsResult.join('`'),
          },
        })
        const isMatchExposeBinRandomDiscountDesc =
          !!this.paymentMethodsOptions.find(item =>
            this.isShowCardBinDiscount(item)
          )
        if (isMatchExposeBinRandomDiscountDesc) {
          daEventCenter.triggerNotice({
            daId: '1-11-1-144',
            extraData: {
              payment_list: matchBinRandomDiscountPaymentsResult.join('`'),
            },
          })
        }
      } else {
        daEventCenter.triggerNotice({
          daId: '1-11-1-143',
          extraData: {
            is_full: 0,
            payment_list: '',
          },
        })
      }
    },
    isShowCardBinDiscount(item) {
      if (this.checkout?.results?.bin_discount_list?.length > 1) {
        let is_discount_type = false
        let is_match_payments = this.checkout?.results?.bin_discount_list
          .map(binDiscountItem => {
            const { discount_type = 0, match_payments = [] } =
              binDiscountItem || {}
            const matchPayment = (match_payments || []).some(
              v => v === item.code
            )
            if (discount_type != 0) {
              is_discount_type = true
            }
            return matchPayment
          })
          .some(isMatchPayment => isMatchPayment)
        return (
          item.enabled &&
          is_discount_type &&
          is_match_payments &&
          this.isShowCardBinDiscountABT
        )
      }
      // 当前支付方式是否展示在线支付随机立减优惠
      const showOnlineRandomDiscount = this.isShowOnlineRandomDiscount(item)

      const { discount_type = 0, match_payments = [] } =
        this.checkout?.results?.bin_discount_list?.[0] || {}
      const matchPayment = (match_payments || []).some(v => v === item.code)
      return (
        item.enabled &&
        discount_type &&
        matchPayment &&
        this.isShowCardBinDiscountABT &&
        // 如果同时存在在线支付优惠随机立减和卡bin随机立减，只展示在线支付优惠随机立减
        (discount_type === 3
          ? !showOnlineRandomDiscount && !!this.cardBinDiscountDesc
          : true)
      )
    },
    isShowDiscountTips(item) {
      return (
        this.isShowOnlinePayDiscount(item) || this.isShowCardBinDiscount(item)
      )
      // return this.isDislabel(item) && (this.isShowOnlinePayDiscount(item) || this.isShowCardBinDiscount(item))
    },
    handleCardBinDiscountTips() {
      const { SERVER_TYPE } = gbCommonInfo
      let articleId = 1695
      switch (SERVER_TYPE) {
        case 'usa':
          articleId = 1753
          break
        case 'eur':
          articleId = 1659
          break
        default:
          articleId = 1695
      }
      window.open(`/payment-discount-a-${articleId}.html`, '_blank')
    },
    getPaymentLogoUrl(item) {
      if (!this.isDisabledPayment(item)) {
        return this.banksConfig?.[item.code]?.bankLogo || item.logo_url
      }
      return item.gray_logo_url
    },
    getPaymentTitle(item) {
      if (item.code == 'PayPal-GApaypal' && this.paypalGaVault?.valid) {
        if (!this.isDisabledPayment(item) && this.paypalGaVault?.express) {
          return this.language?.SHEIN_KEY_PC_26681
        } else if (this.paypalGaVault?.type == PPGA_CHECK_TYPE.CHECKED) {
          return (
            this.ppgvAccountInfo?.signUpEmail ||
            this.language?.SHEIN_KEY_PC_20468
          )
        }
      }
      return (
        (item?.bank_list?.length &&
          this.banksConfig?.[item.code]?.bankCodeText) ||
        (item.show_title && item.title) ||
        ''
      )
    },
    isDisabledPayment(item) {
      return (
        item.enabled == 0 ||
        this.status?.disabled?.[item.code] ||
        !this.checkPaymentDisplay(item.code)
      )
    },
    transformImg: transformImg,
    template: _commonTemplate,

    changeDefaultSelectTokenPay(item) {
      if (this?.$store?.dispatch) {
        this.handleSelectTokenPay(item)
      }
    },
    checkPaymentDisplay (paymentCode = '') {
      return (this.paymentDisplayFunc || this.$parent?.paymentDisplay)?.(paymentCode)
    },
    getPaymentDescription (paymentItem = {}) {
      return (this.paymentDesFunc || this.$parent?.paymentDes)?.(paymentItem)
    },
    getPayLimitTips (paymentCode = '') {
      return (this.payLimitTipShowFunc || this.$parent?.payLimitTipShow)?.(paymentCode)
    },
    handleClickPayment() {
      this.$emit('select-payment', ...arguments)
      this.$parent?.clickPayment?.(...arguments)
      console.log('handleClickPayment arguments >>', arguments, this, this.$parent)
    },
    setFoldIconWidth() {
      if (!this.$refs.foldIcon) return
      const foldIconWidth = this.$refs.foldIcon.offsetWidth
      const count = this.foldIconList.length
      if (count) {
        const iconTotalWidth = count * FOLD_ICON_ITEM_STYLE.width + (count - 1) * FOLD_ICON_ITEM_STYLE.marginLeft
        
        if (foldIconWidth >= iconTotalWidth) {
          this.isShowFoldMore = false
          this.foldIconStyle = {
            width: foldIconWidth
          }
        } else {
          let maxCount = Math.floor((foldIconWidth - FOLD_ICON_ITEM_STYLE.width) / (FOLD_ICON_ITEM_STYLE.width + FOLD_ICON_ITEM_STYLE.marginLeft)) + 1
          let newWidth = maxCount * FOLD_ICON_ITEM_STYLE.width + (maxCount - 1) * FOLD_ICON_ITEM_STYLE.marginLeft
          this.foldIconStyle = {
            width: newWidth
          }
          this.isShowFoldMore = true
        }
      }
    },

    // 查询权益优惠券列表
    async getCouponByCodes (codes) {
      const res = await schttp({
        method: 'POST',
        url: '/api/prime/postCouponByCouponCodes/query',
        data: { codes }
      })
      return res
    },

    // 获取优惠券数据
    async getData () {
      const codes = (this.checkout?.order?.coupon_list || [])?.map(_ => _.coupon)
      if (codes.length == 0) {
        this.couponList = []
        return
      }
      const res = await this.getCouponByCodes(codes)
      this.couponList = res?.info?.list || []
    },

    isDisabledPay(item) {
      if (!this.isHadBinCoupons) {
        return false
      }
      return !(this.isHadBinCoupons && item?.code == 'routepay-card')
    },

    handlerTips() {
      this.$parent?.setTips({
        txt: this.language?.SHEIN_KEY_PC_31110,
      })
    },
    codTipExpose() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-226',
      })
    },
    clickCodTips() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-227',
      })
    },   
    arrayContainsAll(arr, target) {
      return target.every(element => arr.includes(element))
    },
    // 获取券支持的卡bin
    async validateBin() {
      const nowCouponsList = (this.usableCouponList || [])?.filter(item => item?.card?.card_codes?.length > 0)?.map(item => item?.coupon)
      if (nowCouponsList?.length === 0) return
      const isHad = this.arrayContainsAll(this.couponBinList.map(item => item?.coupon_code), nowCouponsList)

      if (isHad && this.couponBinList?.length > 0) return
      try {
        const data = {
          coupon_codes: nowCouponsList,
          bin_list: this.routePayCardBinList
        }

        const res = await schttp({
          method: 'POST',
          url: '/api/coupon/validBin/get',
          data
        })
        

        if (res?.code == 0) {
          this.$emit('assign-state', {
            couponBinList: res?.info?.coupon_bin_list || []
          })
        }
      } catch (error) {
        return error
      }
    }
  },
  emits: ['show-paypalga-type', 'unfold', 'toggle-ppgv-type', 'toggle-afterpay-type', 'toggle-signup-pay-type', 'select-payment', 'assign-state'],
}
</script>

<style lang="less">
.font-bold {
  font-family: Adieu-Bold, Adieu;
  font-weight: bold;
}
.sui_icon_doubt_14px_1 {
  color: @sui_color_gray_dark1;
}
.check-payment{
		.check-title{
			.payment-unselect{
        margin-right: 5px;
				color: @sui_color_unusual;
			}
      .cod-reason-popover {
        display: inline-block;
        padding: 5px 0;
        margin-right: 5px;
        color: #999;

        .cod_link {
          text-decoration: underline;
          cursor: pointer;
        }
        .sui-popover__content{
          width: 300px;
        }
      }
      .cod-reason-inner {
        line-height: 18px
      }
		}

		.paytm-upi-tip {
			.padding-l(12px);
			font-size: 12px;
			color: #666;
			position: relative;

			.icon-jiantou-copy {
				position: absolute;
				right: -18px;
			}
		}
		.open-upi {
			.icon-jiantou-copy {
				transform: rotate(180deg);
			}
		}
		.paytm-upi-input {
			width: 285px;
			font-size: 12px;
			.margin-l(100px);
			position: relative;

			input {
				width: 100%;
				height: 36px;
				/*border: 1px solid rgba(0, 0, 0, 0.1);*/
				&.upi-error {
					border: 1px solid #FF411C;
				}
			}
			.upi-error-tip {
				padding-top: 4px;
				color: #FF411C;
			}
			.icon-success, .icon-zhuyi- {
				font-size: 20px;
				color: #5EBD66;
				position: absolute;
				top: 6px;
				.right(10px);
			}
			.icon-zhuyi- {
				color: #D53333;
			}
			&.hint {
				padding-top: 16px;
				width: auto;
				color: #767676;
				font-weight: normal;
			}
		}

		.payment-list {
			position: relative;
			.payment-loading {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				.left(0);
				z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
				.loading-cover {
					width: 100%;
					height: 100%;
					position: absolute;
					background: #fff;
					opacity: 0.5;
				}
				.loading-ball {
					position: absolute;
					top: 50%;
					.left(50%);
				}
			}
			.cshe-adr-select {
				display: inline-block;
        margin: 0;
        .margin-l(10px);
        vertical-align: middle;
        .adr-select {
          padding: 7px;
          line-height: 1;
          height: auto;
          font-size: 13px;
          border: 1px solid rgba(0,0,0,.4);
          .placeholder(rgba(0,0,0,.8));
        }
        .list-show {
          .adr-select {
            border: 1px solid rgba(0,0,0,.8);
          }
        }
        .icon-jiantou-copy,
        .sui_icon_more_down_14px {
          top: 6px;
          color: rgba(0,0,0,.2);
          .right(8px);
        }
			}
			.payment-save {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.cod_help {
			text-decoration: underline;
			color:#f00;
      cursor: pointer;
		}
		.cod_help_dec {
			line-height: 18px;
			margin-right: 30px;
			font-size: 12px;
		}
		.cod_help_fee {
			margin-right: 10px;
			font-size: 12px;
		}
		.pament_opacity {
			opacity: 0.35;
		}
	}
.check-payment {
  background: #fff;
  &__more {
    padding: 12px;
    color: @sui_color_gray_dark3;
    cursor: pointer;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;

    > i {
      color: #222;
    }
  }
  .payment-list .payment-item {
    &.fold {
      position: absolute;
      top: 0;
      left: -1000%;
    }
  }
  .ml-100 {
    margin-left: 100px;
  }
}

.check-fold {
  &-text {
    flex-shrink: 0;
    line-height: 1;
    .margin-r(2px);
  }
  &-icon {
    display: flex;
    align-items: center;
    margin: 0 4px 0 8px;
    flex-wrap: wrap;
    height:24px;
    overflow: hidden;
    position: relative;

    &-item {
      height: 24px;
      display: inline-block;
      flex-shrink: 0;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    &-more {
      background-color: @sui_color_gray_weak1;
      border-radius: 2px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #959595;
      position: absolute;
      right: 0;
    }
  }
}

.discount-tips-item {
  display: inline-block;
  padding: 1px 6px;
  background-color: @sui_color_promo_bg;
  border: 1px solid rgba(196, 74, 1, 0.3);
  color: @sui_color_promo_dark;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}
.card-bin-discount-tips {
  .doubt-icon {
    padding-left: 10px;
    color: #959595;
    line-height: 16px;
    position: relative;
    top: 1px;
  }
}

.checkout-payment__effiency {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 8px;
  .check-title {
    padding: 16px 16px 0;
    border-bottom: 0;
    margin-bottom: 8px;

    &.check-title-animate-shake {
      animation-name: paymentMethodShake;
    }

    > h4 {
      font-family: 'Arial Black';
      font-weight: 900;

      & when (@IS_RW) {
        font-family: Adieu;
      }
    }
  }

  .payment-list {
    padding: 0 16px;

    .payment-item {
      padding: 8px;
      margin-bottom: 8px;
    }
    .payment-selected {
      border: 1px solid @sui_color_main;
    }
    .payment-item__effiency-radio {
      .sui-radio__check {
        .margin-r(8px);
      }
    }
  }
  .sui_icon_doubt_14px_1 {
    color: @sui_color_gray_dark2;
  }

  .card-bin-discount-tips {
    display: flex;
    .doubt-icon {
      .padding-l(5px);
    }
  }
}

.payment-item__effiency-right {
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 100px);
  .margin-l(8px);

  .payment-extra-more {
    .payment-des {
      .padding-l(0);
    }
    .cshe-adr-select {
      .margin-l(4px);
    }
    .payment-item-tip {
      padding: 0;
      .padding-l(5px);
      i {
        color: @sui_color_gray_dark2;
      }
    }
    .paytm-upi-tip {
      .padding-l(0);
    }

    .payment-discount-zero {
      .margin-l(4px);
      .margin-r(0);
    }
    .extra-tip {
      .padding-l(0);
      
      .extra-tip-link {
        padding: 5px;
      }
    }
    .ppgv-arrow-effiency {
      display: inline-block;
      vertical-align: middle;
      .margin-l(4px);
    }
  }
  .slide-up-down-out {
    margin-top: 4px
  }
  .paytm-upi-input {
    .margin-l(0);
    &.hint {
      padding: 0;
      // padding-top: 4px;
      .margin-l(0);
    }
  }
  .card-logo {
    .margin-l(0);
    margin-top: 8px;
  }
  .discount-percent_tips {
    margin: 4px 0;
  }
  
  .payment-des {
    .padding-l(0);
  }

  .cod_help_fee {
    .margin-r(0);
  }
  .cod_help_dec {
    .margin-r(0);
  }
}
.dicount-flex-sy {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.select-bank-wrap {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
  .select-bank-text {
    font-size: 12px;
    padding: 0 5px;
    color: @sui_color_gray_dark3;
    
  }
  .txt-error {
    color: @sui_color_unusual;
  }
}

.payment-list {
  .token-pay-item {
    width: 100%;
  
    .payment-extra-more {
      display: flex;
    }
    .left-drawer {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.disabled-tip {
  color: #BF4123;
  font-size: 12px;
  font-family: SF Pro;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 17px;
  word-wrap: break-word;
  padding-left: 32px;
}

@keyframes paymentMethodShake {

  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-6px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {

    transform: translate3d(6px, 0, 0);
  }
}
</style>
