/* globals Klarna */
// import { mapState, mapMutations } from 'vuex'
import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
import {
  asyncLoadFile,
  parseQueryString,
} from '@shein/common-function'
import { PPGA_CHECK_TYPE, AFTER_PAY_CHECK_TYPE, SIGNUP_CHECK_TYPE } from '../common/constants'
import errorReport from '@/public/src/pages/common/handlePay/log'

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

// const { checkout = {} } = window.gbCoSsrData || window.gbCoAgainSsrData || window.gbGiftcardCoSsrData || window.gbBuyPrimeCoSsrData
//支持inline模式的渠道，一个渠道可以对接多个支付编码
const channelList = ['klarna', 'paypal']
import { abtservice } from 'public/src/services/abt'
import { routerReplace } from 'public/src/pages/common/handlePay/tools'
import { daEventCenter } from 'public/src/services/eventCenter/index'
let billno = ''
let paypalSdkTimeout = null

daEventCenter.addSubscriber({ modulecode: '2-31' })

const paypalChannelSdks = {
  'PayPal-GApaypal': [
    //Load the client component
    'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
    //Load the PayPal Checkout component
    'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
    //手机设备数据
    'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
  ],
  'PayPal-Venmo': [
    //Load the client component
    'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
    //Load the PayPal Checkout component
    'https://js.braintreegateway.com/web/3.85.2/js/venmo.min.js',
    //手机设备数据
    'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
  ],
}

export default {
  data() {
    return {
      payInlineAbtInfo: {}, // klarna等分期直连支付方式abt
      payInlineSessionInfoCache: {},
      payInlineSessionInfo: {},
      needPayInlinePaymentMethods: [],
      showWidgetForm: 1, // 0：Widget加载失败，不展示直接隐藏 1:初始态 2： Widget加载成功
      chosenChannel: '',	//目前选中支付方式的渠道
      paypalGaBtn: false,	//是否已经渲染过paypal按钮
      paypalDeviceId: '',
      nowPageType: 0, //1：下单页，2：二次下单页，3：礼品卡下单页，4：礼品卡二次下单页，5：订单列表页, 6: 单独购页，9: 付费会员二次下单
      fullLoading: false,
      paypalSdkLauching: false, //paypal sdk是否正在初始化
      //sdkTokenReqing: false,
      nowInlineCacheKey: '',
      nowPaypalCacheKey: '',
      paypalSdkLoadSuccess: {}, // paypal直连支付渠道sdk加载状态
      paypalGaVault: {
        valid: false,
        tipShow: false,
        radioType: PPGA_CHECK_TYPE.CHECKED,	//用于展示哪个勾选
        type: PPGA_CHECK_TYPE.CHECKED,			//实际应用的勾选
        visibleArrow: true, // 是否展示valuting选择弹窗
        express: false, // 是否使用快捷选中方式 - 未签约，且允许选择是否记住卡号
      },
      afterpayVault: {
        valid: true,
        tipShow: false,
        radioType: AFTER_PAY_CHECK_TYPE.CHECKED,	//用于展示哪个勾选
        type: AFTER_PAY_CHECK_TYPE.CHECKED,			//实际应用的勾选
        visibleArrow: true, // 是否展示valuting选择弹窗
        switch: false, // 是否开启token支付
        title: 'A', // 支付方式标题
      },
      signupPayVault: {},
      //paypal ga vaulting用户信息
      PPGVAccountInfo: {},
      paypalVenmoDeviceId: '',
      venmoInstance: null,
      paypalVenmoBtnShow: false, //是否已经渲染过paypal 展示Venmo按钮
      processList: [],  //用于记录paypal-ga的支付步骤走到了哪些
			defaultInlineMethods: ['PayPal-GApaypal', 'PayPal-Venmo'], // 默认走inline流程，不依赖abt
			PPGA_CHECK_TYPE,
      SIGNUP_CHECK_TYPE,
			nowCheckoutScene: '', // 当前下单页场景 Checkout：普通订单下单页，CheckoutAgain： 普通二次下单，OrderDetail：普通订单详情，GiftCard：礼品卡下单页，GiftCardAgain：礼品卡二次下单页，OrderList：订单列表页, BuyPrime: 付费会员购买页, Xtra: 超省卡单独购，XtraAgain: 超省卡单独购二次下单，BuyPrimeAgain: 付费会员二次下单
      klarnaLoadWidgetSwitch: false
    }
  },
	computed: {
		paypalBtnShow(){
			//如果是paypalga，且不是已签约（已签约不展示paypal按钮，直接调用支付接口）
			return this.chosenChannel == 'paypal' && this.showWidgetForm == 2 && !this.isContinueSign
		},
		ppgvAccountInfo(){
			if( !this.checkout.results?.paymentMethods?.length ) return {}
			let item = this.checkout.results.paymentMethods.filter(item => item.code == 'PayPal-GApaypal')
			item = item && item[0] && item[0].paymentSignUp && item[0].paymentSignUp[0] || {}
			return item
		},
		afterpayAccountInfo(){
			if( !this.checkout.results?.paymentMethods?.length ) return {}
			let item = this.checkout.results.paymentMethods.filter(item => item.code == 'afterpay-card')
			item = item && item[0] && item[0].paymentSignUp && item[0].paymentSignUp[0] || {}
			return item
		},
    signupAccountInfo() {
      if ( !this.checkout.results?.paymentMethods?.length) return {}
      // let item = this.checkout.results.paymentMethods.filter(item => item.code == 'dlocal-nupay')
      // item = item && item[0] && item[0].paymentSignUp && item[0].paymentSignUp[0] || {}
      // return item
      const signupAccountInfo = {}
      this.checkout.results.paymentMethods.forEach(item => {
        let accountInfo = item && item.paymentSignUp && item.paymentSignUp[0] || {}
        signupAccountInfo[item.code] = accountInfo
      })
      return signupAccountInfo
    },
		isContinueSign(){
			return this.paypalGaVault.type == '1' && this.ppgvAccountInfo?.signUpEmail
		},
		// 存在平台商品
		hasStore () {
			let cartList = []
			// 普通一次下单
			if (this.nowPageType === 1) {
				cartList = this.checkout?.results?.carts?.carts || []
			}
			// 普通二次下单
			if (this.nowPageType === 2) {
				cartList = this.checkout?.order?.mall_list || []
			}
      // 超省卡/付费会员二次下单
      if (['BuyPrimeAgain', 'XtraAgain'].includes(this.nowCheckoutScene)) {
				cartList = this.checkout?.order?.mall_list || []
			} else if (['BuyPrime', 'Xtra'].includes(this.nowCheckoutScene)) {
        const { business_model, company_id } = this.primePriceInfo ?? {}
        return business_model == 1 || (business_model == 0 && company_id == 75) || business_model == 4
      }
			return cartList.some(item => item.business_model == 1 || (item.business_model == 0 && item.company_id == 75) || item.business_model == 4)
		},
		checkoutType () {
			if ([3, 4].includes(this.nowPageType)) return 'giftcard'
			if (this.nowPageType === 6 || this.nowCheckoutScene === 'BuyPrimeAgain') return 'buyprime'
			if (['Xtra', 'XtraAgain'].includes(this.nowCheckoutScene)) return 'xtra'
			return ''
		}
	},
	created() {
		this.getPageTypeComplete = false
		if (typeof window !== 'undefined') {
			this.getPageType()
		}
    // this.initPaypalGaVault()
    // this.initAfterpayVault()
    this.getInlineAbt()
  },
	mounted(){
		if (!this.getPageTypeComplete) {
			this.getPageType()
		}
    this.initPaypalGaVault()
		this.initAfterpayVault()
    this.initSignupPayVault()
    // this.getInlineAbt()
		this.initKlarnaInlineSdk()
	},
  methods: {
		setNowCheckoutScene (scene = '') {
			this.nowCheckoutScene = scene || ''
		},
		initPaypalGaVault() {
      const signAbtParams = parseQueryString(
        this.checkout?.results?.paypalSignAbt?.p || ''
      )
      // 无论签约与否都开启
      if (
        signAbtParams?.signed === 'open' &&
        signAbtParams?.unsigned === 'open'
      ) {
        this.paypalGaVault.valid = true
      }
      // 签约才开启
      if (
        signAbtParams?.signed === 'open' &&
        signAbtParams?.unsigned !== 'open'
      ) {
        this.paypalGaVault.valid = !!this.ppgvAccountInfo?.signUpEmail
      }
      // 未签约才开启
      if (
        signAbtParams?.signed !== 'open' &&
        signAbtParams?.unsigned === 'open'
      ) {
        this.paypalGaVault.valid = !this.ppgvAccountInfo?.signUpEmail
      }
      // 无论签约与否都不开启 默认
      if (
        signAbtParams?.signed !== 'open' &&
        signAbtParams?.unsigned !== 'open'
      ) {
        this.paypalGaVault.valid = false
      }

      const backupValid = this.paypalGaVault.valid

      if (this.hasStore) {
        this.paypalGaVault.valid = true
        this.paypalGaVault.radioType = PPGA_CHECK_TYPE.CHECKED
        this.paypalGaVault.type = PPGA_CHECK_TYPE.CHECKED
        this.paypalGaVault.visibleArrow = this.checkout?.checkoutBFFAbtInfo?.Platformitemsvaultingoption?.param?.platform_items_vaulting_option === 'on' ? true : false
      } else {
        this.paypalGaVault.valid = backupValid
        this.paypalGaVault.visibleArrow = true
      }
      // wiki.pageId=1141988501
      this.paypalGaVault.express = false
      if (this.paypalGaVault.valid && this.paypalGaVault.visibleArrow && signAbtParams?.unsigned === 'open') {
        if (!this.ppgvAccountInfo?.signUpEmail) {
          this.paypalGaVault.express = true
          this.paypalGaVault.visibleArrow = false
        }
      }

      if (!this.paypalGaVault.valid) {
        this.paypalGaVault.radioType = PPGA_CHECK_TYPE.UNCHECKED
        this.paypalGaVault.type = PPGA_CHECK_TYPE.UNCHECKED
      }
    },
	initAfterpayVault() {
		const orderGoodsList = this.checkout?.order?.orderGoodsList || []
		let hasPrime = false
		let hasXtra = false
		if( orderGoodsList.length ){
			hasPrime = orderGoodsList.some(item => !!item.is_prime_goods)
			hasXtra = orderGoodsList.some(item => !!item.is_xtra_goods)
		}

      const noCardTokenPayAbtInfo = this.checkout?.results?.noCardTokenPayAbtInfo

      // ABT：开启token支付
      const paymentTokenSupport = noCardTokenPayAbtInfo?.PaymentUnsignExp?.p?.PaymentSignupShow === 'Show' || false
      // ABT：是否默认勾选remember
      
      const paymentInfo = this.checkout?.results?.paymentMethods?.find?.(item => item.code === 'afterpay-card') || {}

      const afterpaytokenRemember = paymentInfo?.default_sign_remember === 1
      // ABT：文案的abt
      const afterpaySavedword = noCardTokenPayAbtInfo?.afterpaySavedword?.p?.afterpaySavedwordStatus === 'A'
		
      this.afterpayVault.switch = paymentTokenSupport ? true : false
      this.afterpayVault.title = afterpaySavedword ? 'A' : 'B'
      this.afterpayVault.valid = paymentTokenSupport ? true : false
      this.afterpayVault.radioType = (afterpaytokenRemember || hasPrime || hasXtra) ? AFTER_PAY_CHECK_TYPE.CHECKED : AFTER_PAY_CHECK_TYPE.UNCHECKED
      this.afterpayVault.type = (afterpaytokenRemember || hasPrime || hasXtra) ? AFTER_PAY_CHECK_TYPE.CHECKED : AFTER_PAY_CHECK_TYPE.UNCHECKED
		
      // 有签约信息
      if(this.afterpayAccountInfo?.id){
        // 不展示勾选开关
        this.afterpayVault.valid = false
        this.afterpayVault.radioType = AFTER_PAY_CHECK_TYPE.CHECKED
        this.afterpayVault.type = AFTER_PAY_CHECK_TYPE.CHECKED
      } else {
        // 无签约信息,展示老文案
        this.afterpayVault.title = ''
      }
    },

    initSignupPayVault() {
      if (!this.checkout.results?.paymentMethods?.length) return {}
      this.checkout.results.paymentMethods.forEach(item => {
        const { code = '', default_sign_remember, needToSign } = item
        if (needToSign) {
          this.signupPayVault[code] = {
            valid: true,
            radioType: default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
            type: default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
          }
          const accountInfo = this.signupAccountInfo[code] || {}
          if (accountInfo.id) {
            this.signupPayVault[code].valid = false
            this.signupPayVault[code].radioType = SIGNUP_CHECK_TYPE.CHECKED
            this.signupPayVault[code].type = SIGNUP_CHECK_TYPE.CHECKED
          }
        }
      })
    },
  
    async getInlineAbt() {

			this.payInlineAbtInfo = this.checkout?.results?.payInlineAbtInfo || {}

      this.processList.push('PayInlineAbt')
      this.recordPayLineErr('/inline-pp-abt-error', { originAbt: this.payInlineAbtInfo })

      //如果abt没值  则处理下
      if (this.payInlineAbtInfo && !Object.keys(this.payInlineAbtInfo).length) {
        const { PayInline } = await abtservice.getUserAbtResult({
          posKeys: 'PayInline',
        })
        let payInlineInfo = {}
        if (PayInline && PayInline.param) {
          let channel = PayInline.param.split('&')
          channel.forEach((item) => {
            let inlineInfoItem = item.split('=')
            if (inlineInfoItem[1]) {
              payInlineInfo[inlineInfoItem[0]] = inlineInfoItem[1].split(',')
            }
          })
        }
        if (payInlineInfo['paypal']) {
          if (!payInlineInfo['paypal'].includes('PayPal-GApaypal')) {
            payInlineInfo['paypal'].push('PayPal-GApaypal')
            this.recordPayLineErr('/inline-pp-abt-error', {
              abtInfo: this.payInlineAbtInfo,
              PayInline
            })
          }
        } else {
          payInlineInfo.paypal = ['PayPal-GApaypal']
          this.recordPayLineErr('/inline-pp-abt-error', {
            abtInfo: this.payInlineAbtInfo,
            PayInline
          })
        }

        this.payInlineAbtInfo = {
          ...payInlineInfo,
          paypal: [ ...(payInlineInfo?.paypal || []), ...this.defaultInlineMethods ]
        }

        //排查问题记录日志
        this.recordPayLineErr('/inline-pp-abt-record', {
          abtInfo: payInlineInfo,
          PayInline
        })

      } else {
        this.payInlineAbtInfo = {
          ...this.payInlineAbtInfo,
          paypal: [ ...(this.payInlineAbtInfo?.paypal || []), ...this.defaultInlineMethods ]
        }
      }

      //如果上面到这里payInlineAbtInfo不存在或者为空对象，则兜底paypal inline
      if( !this.payInlineAbtInfo || !Object.keys(this.payInlineAbtInfo).length ){
        this.payInlineAbtInfo = { paypal: [...this.defaultInlineMethods] }
        this.recordPayLineErr('/inline-pp-abt-empty', {
        })
      }
    },
    judgeNeedPayInlineChannel: function (paymentMethod) {
      let payInlineChannel = ''
      if (this.needPayInlinePaymentMethods.includes(paymentMethod)) {
        for (let key in this.payInlineAbtInfo) {
          if (this.payInlineAbtInfo[key].includes(paymentMethod) && channelList.includes(key)) {
            payInlineChannel = key
          }
        }
      }
      this.chosenChannel = payInlineChannel
      return payInlineChannel
    },

    getPageType(){
      if (typeof window === 'undefined') return
      const isCheckout = location.pathname.indexOf('/checkout_again') == -1
      const isGiftCard = location.pathname.indexOf('/giftcard') > -1
      const isBuyPrime = location.pathname.indexOf('/buyprime') > -1 || 'BuyPrimeAgain' === this.nowCheckoutScene
      const isBuyXtra = ['Xtra', 'XtraAgain'].includes(this.nowCheckoutScene)
      if (isCheckout && !isGiftCard && !isBuyPrime && !isBuyXtra) { // 普通一次下单
        this.nowPageType = 1
      } else if (!isCheckout && !isGiftCard && !isBuyPrime && !isBuyXtra) { // 普通二次下单
        this.nowPageType = 2
      } else if (isCheckout && isGiftCard) { // 礼品卡一次下单
        this.nowPageType = 3
      } else if (!isCheckout && isGiftCard) { // 礼品卡二次下单
        this.nowPageType = 4
      } else if (isBuyPrime) { // 单独购
        this.nowPageType = 6
      }
      this.getPageTypeComplete = true
      // if( location.pathname.indexOf('/orders/list') > -1 ){
      // 	this.nowPageType = 5
      // }
    },
    loadingStatus(isShow){
      //this.status.checkout = isShow ? 1 : 0
      this.fullLoading = isShow
    },

    async loadPaypalScript(payment_method){
      if( this.paypalSdkLoadSuccess[payment_method] ) return
      const payInlineAbtInfo =  this.payInlineAbtInfo || {} // klarna等分期直连支付方式abt
      const channelKeys = Object.keys(payInlineAbtInfo)
      //paypal-ga渠道sdk
      if( channelKeys.includes('paypal') ){
        const paypalSdks = paypalChannelSdks[payment_method] || []
        let loadCount = 0
        const arr = []
        paypalSdks.forEach(src => {
          arr.push(
            asyncLoadFile({
              label: 'script',
              attrs: {
                src
              },
            }).then(() => {
              loadCount++
            })
          )
        })

        this.loadingStatus(true)
        await Promise.all(arr).then(() => {
          if( loadCount == paypalSdks.length ){
            this.paypalSdkLoadSuccess[payment_method] = true
          }
        }).catch(err => {
          console.log('load paypal sdk err', err)
          this.paypalSdkLoadSuccess[payment_method] = false
        })
      }
    },

    //根据渠道调用: this[payInlineChannel](this.status.cardPay)
    async paypal(payment_method = ''){
      const info = this.payInlineSessionInfo[payment_method]
      const clientToken = info && info.clientToken
      let profileId = (info && info.profileId) || ''
      const _this = this
      await this.loadPaypalScript(payment_method)

      //console.log('paypal ga client token: ', clientToken)
      if( !clientToken || !window.braintree || !this.paypalSdkLoadSuccess[payment_method]){
        this.showWidgetForm = 1
        this.loadingStatus(false)
        //console.log('Missing client token', clientToken)

        if (!clientToken) {
          this.recordPayLineErr('inline/paypal/initSdk', { msg: '缺少clientToken', payment_method, clientToken })
          this.sendErrorReport('paypal_client_token_again_error', {
            failure_type: 'api',
            client_url: '/api/checkout/channelSession/create',
            description: 'Paypal 缺少clientToken',
          })
        }

        if (!this.paypalSdkLoadSuccess[payment_method]) {
          this.recordPayLineErr('inline/paypal/initSdk', { msg: 'sdk加载失败', payment_method })
          this.sendErrorReport('paypal_init_sdk_error', {
            failure_type: 'sdk',
            client_url: '/third/sdk/error',
            description: 'Paypal获取 session script 失败',
          })
        }
        return
      }

      if (payment_method == 'PayPal-Venmo') {
        if (!profileId) {
          profileId = gbCommonInfo?.IS_RW ? '3535562754895009667' : '3535110066272371717'
        }
        this.initPayPalVenmo(clientToken, payment_method, profileId)
        return
      }
      this.paypalVenmoBtnShow = false

      // 以下为PayPal-GApaypal处理逻辑

      //目前的paypal按钮对应的clientToken参数不变的话则不重新生成按钮
      if( this.nowPaypalCacheKey && this.nowPaypalCacheKey == this.nowInlineCacheKey ) {
        this.showWidgetForm = 2
        this.loadingStatus(false)
        return
      }

      if( this.paypalSdkLauching ) return
      this.loadingStatus(true)
      this.paypalSdkLauching = true

      paypalSdkTimeout = setTimeout(() => {
        if( !this.paypalGaBtn ){
          this.loadingStatus(false)
          this.recordPayLineErr('inline/paypal/initSdk', { msg: 'sdk响应时间过长' })
          console.log('paypal sdk timeout')
        }
      }, 20 * 1000)

      this.processList.push('paypalClientCreate: ' + clientToken)

      // Create a client.
      await braintree.client
        .create({
          authorization: clientToken,
        })
        .then(function (clientInstance) {
          //device data
          braintree.dataCollector
            .create({
              client: clientInstance,
            }).then(function(dataCollectorInstance) {
              _this.paypalDeviceId = dataCollectorInstance.deviceData
              if (!_this.paypalDeviceId) {
                _this.recordPayLineErr('inline/paypal/initSdk', { msg: payment_method + ' 返回risk data为空', dataCollectorInstance })
              }
            }).catch(err => {
              console.log('paypal deviceData err', err)
              _this.recordPayLineErr('inline/paypal/initSdk', { msg: payment_method + ' 获取risk data失败', err })
            })

          //初始化组件
          console.log('Initialize components')
          // Create a PayPal Checkout component.
          return braintree.paypalCheckout.create({
            client: clientInstance,
          })
        })
        .then(function (paypalCheckoutInstance) {
          console.log('Load the PayPal JS SDK')
          // Load the PayPal JS SDK (see Load the PayPal JS SDK section)

          let currency = ''
          if ([2, 4, 5].includes(_this.nowPageType) || ['XtraAgain', 'BuyPrimeAgain'].includes(_this.nowCheckoutScene)) {
            currency = _this.checkout.order.currency_code
          } else if (_this.nowPageType == 1) {
            currency = _this.currencyCode
          } else if ([3, 6].includes(_this.nowPageType) || ['Xtra'].includes(_this.nowCheckoutScene)) {
            currency = _this.currency
          }

          return paypalCheckoutInstance.loadPayPalSDK({
            currency: currency || 'USD',
            intent: 'capture',
          }).then(data => {
            daEventCenter?.triggerNotice?.({
              daId: '2-31-5',
              extraData: {
                payment_method,
                return_status: 'sdkjsapi_return_success'
              }
            })
            return data
          }).catch(err => {
            daEventCenter?.triggerNotice?.({
              daId: '2-31-5',
              extraData: {
                payment_method,
                return_status: 'sdkjsapi_return_failure'
              }
            })
            _this.ppBraintreeErrorReport({
              apiScene: 'loadPayPalSDK',
              error: err,
              payment_method
            })
            return {}
          })

        }).then(function (paypalCheckoutInstance) {
          return _this.renderPaypalBtn(paypalCheckoutInstance)

        }).catch(function (err) {
          _this.paypalSdkLauching = false
          _this.paypalGaBtn = false
          _this.loadingStatus(false)
          console.log('paypal ga sdk error: ', err)
          _this.recordPayLineErr('inline/paypal/initSdk', { msg: '初始化失败', err })
          clearTimeout(paypalSdkTimeout)
          // Handle component creation error
          return null
        })

    },

    //根据渠道调用: _this[`${payInlineChannel}LoadWidget`]
    paypalLoadWidget(){
    },

		renderPaypalBtn(paypalCheckoutInstance){
			// if( this.paypalGaBtn ) {
			// 	this.showWidgetForm = 2
			// 	return
			// }
			const { host, IS_RW } = typeof window === 'undefined' ? {} : gbCommonInfo || {}
			const giftCardPath = location.pathname.indexOf('/giftcard') > -1 ? '/giftcard' : ''
			const _this = this
			const style = {
				layout: 'vertical',
				color: IS_RW ? 'blue': 'black',
				shape: 'rect',
				label: 'paypal',
				height: 54,
			}
			console.log('rendering paypal btn')
      this.processList.push('paypalBtnRender')

      // 签约按钮
      return paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        style,
        createOrder: function () {
          let order = window.orderAgainVue
            ? _this.checkout.order
            : _this.orderDate
          let createPayment = {}

          try {
            const shippingAddressOverride = {
              recipientName: `${order.shipping_lastname} ${order.shipping_firstname}`,
              line1: order.shipping_address_1,
              line2: order.shipping_address_2,
              city: order.shipping_city || order.shipping_province,
              countryCode: order.countryCode || _this.checkout?.default_address?.value,
              postalCode: order.shipping_postcode,
              state: order.shipping_province,
              phone: order.shipping_telephone,
            }
            console.log('klarna city: ', shippingAddressOverride.city)
            let amount = order.totalPrice?.amount
            if( [3, 4].includes(_this.nowPageType) ){
              amount = order.currency_total_all
            }

            const config = {
              flow: 'checkout', // Required
              amount, // Required
              currency: order.currency_code, // Required, must match the currency passed in with loadPayPalSDK
              intent: 'capture',
              enableShippingAddress: true,
              shippingAddressEditable: false,
              shippingAddressOverride,
            }
            // jira.FR-10022
            // wiki.pageId=882028333
            // 普通订单一次下单/二次下单/单独购ppga不上送地址
            if ([1, 2, 3, 4, 6].includes(_this.nowPageType) || ['Xtra', 'XtraAgain', 'BuyPrimeAgain'].includes(_this.nowCheckoutScene)) {
              config.enableShippingAddress = false
              delete config.shippingAddressEditable
              delete config.shippingAddressOverride
            }

            //1为签约
            if( _this.paypalGaVault.type == '1' ){
              config.requestBillingAgreement = true
            }

            //return paypalCheckoutInstance.createPayment(config)
            createPayment = paypalCheckoutInstance.createPayment(config).then(data => {
              // 曝光paypal弹窗
              daEventCenter?.triggerNotice?.({
                daId: '2-31-3',
                extraData: {
                  payment_method: 'PayPal-GApaypal'
                }
              })
              return data
            }).catch(err => {
              _this.ppBraintreeErrorReport({
                apiScene: 'createPayment',
                error: err,
                payment_method: 'PayPal-GApaypal'
              })
              return {}
            })

          } catch (e) {
            _this.recordPayLineErr('inline/paypal/initSdk', {
              msg: 'createPayment错误',
              billno: order.billno,
              err: e,
            })
          }
          return createPayment
        },
        onClick: async function(data, actions){
          _this.loadingStatus(true)
          const result = await _this.createOrder()

          let res = result && result.res || {}

          apiReport.report({
            apiPath: 'paypal-create-order',
            code: res?.code,
            isPaid: res?.info?.order?.isPaid,
          })

          _this.loadingStatus(false)
          if( res && res.code == 0 && res.info && res.info.order && !res.info.order.isPaid ){
            billno = res.info.order?.billno || res.info.billno
            return actions.resolve()
          } else {
            apiReport.report({
              apiPath: 'paypal-create-reject',
              err: JSON.stringify(res || {}),
            })
            _this.sendErrorReport(!result || !result?.res ? 'paypal_click_btn_co_error' : 'paypal_click_btn_res_error', {
              failure_type: 'api',
              client_url: '/third/sdk/error',
              description: `Paypal 点击按钮创单失败, ${res?.code}: ${res?.msg}`,
            })
            return actions.reject()
          }
        },

        onApprove: function (data, actions) {
          return paypalCheckoutInstance.tokenizePayment(
            data,
            function (err, payload) {
              // Submit `payload.nonce` to your server
              console.log(payload)
              _this.loadingStatus(true)
              const order = window.orderAgainVue
                ? _this.checkout.order
                : _this.orderDate
              S_Module.require('checkout').paypalGaPay({
                order,
                vm: _this,
                payload,
                type: _this.checkoutType,
                checkoutScene: _this.nowCheckoutScene
              })

              let nonceReturnStatus = 'nonceapi_return_success'

              if( err || !payload || !payload.nonce ){
                if (!err && !payload?.nonce) {
                  nonceReturnStatus = 'nonceapi_return_failure'
                }
                if (err) {
                  nonceReturnStatus = 'nonceapi_not_response'
                  _this.ppBraintreeErrorReport({
                    apiScene: 'tokenizePayment',
                    error: err,
                    payment_method: 'PayPal-GApaypal'
                  })
                }
                _this.recordPayLineErr('inline/paypal/initSdk', {
                  msg: 'payload支付信息错误',
                  billno,
                  payload,
                  err,
                  nonceReturnStatus
                })

                _this.sendErrorReport('paypal_get_nonce_error', {
                  failure_type: 'api',
                  client_url: '/third/sdk/error',
                  description: 'Paypal 获取SDK对应nonce失败, ' + nonceReturnStatus + ': ' + String(err?.message || err?.stack || '').substring(0, 100),
                })
              }
              // 曝光请求渠道SDK获取nonce值结果
              daEventCenter?.triggerNotice?.({
                daId: '2-31-4',
                extraData: {
                  payment_method: 'PayPal-GApaypal',
                  return_status: nonceReturnStatus
                }
              })
            }
          )
        },

        onCancel: function(data) {
          _this.recordPayLineErr('inline/paypal/initSdk', { msg: '支付取消', billno })
          console.log('PayPal payment canceled', JSON.stringify(data, 0, 2))
          // 取消/关闭弹窗点击
          daEventCenter?.triggerNotice?.({
            daId: '2-31-2',
            extraData: {
              payment_method: 'PayPal-GApaypal'
            }
          })
          _this.sendErrorReport('paypal_render_on_cancel', {
            failure_type: 'api',
            client_url: '/third/sdk/error',
            description: `Paypal 支付取消: ${billno}`,
          })
          _this.loadingStatus(false)
          if( [1, 3].includes(_this.nowPageType) && billno ){
            var checkout_againurl = host + giftCardPath + '/checkout/checkout_again/' + billno
            GB_route_jump(checkout_againurl)
          } else if( [2, 4].includes(_this.nowPageType) || ['XtraAgain', 'BuyPrimeAgain'].includes(_this.nowCheckoutScene)){
            _this.status.checkout = 0
          } else if (['Xtra'].includes(_this.nowCheckoutScene)) {
            routerReplace(host + '/xtra_again/' + billno)
          } else if (_this.nowPageType == 6) {
            routerReplace(host + '/buyprime_again/' + billno)
          }
        },

        onError: function(err) {
          // 弹窗异常埋点
          daEventCenter?.triggerNotice?.({
            daId: '2-31-1',
            extraData: {
              payment_method: 'PayPal-GApaypal'
            }
          })
          _this.recordPayLineErr('inline/paypal/initSdk', { msg: '支付信息错误', billno, err: String(err?.message || err?.stack || '').substring(0, 600) })
          _this.sendErrorReport('paypal_render_on_error', {
            failure_type: 'api',
            client_url: '/third/sdk/error',
            description: `Paypal 支付信息错误: ${String(err?.message || err?.stack || '').substring(0, 100)}`,
          })
          console.error('PayPal error', err)
          _this.loadingStatus(false)
          if( [1, 3].includes(_this.nowPageType) && billno ){
            var checkout_againurl = host + giftCardPath + '/checkout/checkout_again/' + billno
            GB_route_jump(checkout_againurl)
          } else if( [2, 4].includes(_this.nowPageType) || ['XtraAgain', 'BuyPrimeAgain'].includes(_this.nowCheckoutScene)){
            _this.status.checkout = 0
          } else if (['Xtra'].includes(_this.nowCheckoutScene)) {
            routerReplace(host + '/xtra_again/' + billno)
          } else if (_this.nowPageType == 6) {
            routerReplace(host + '/buyprime_again/' + billno)
          }
        },
      }).render('#paypal-vault-button').then(function() {
        console.log('render paypal btn success')
        _this.paypalSdkLauching = false
        _this.loadingStatus(false)
        _this.paypalGaBtn = true
        _this.nowPaypalCacheKey = _this.nowInlineCacheKey
        _this.showWidgetForm = 2
        clearTimeout(paypalSdkTimeout)
        // The PayPal button will be rendered in an html element with the ID
        // `paypal-vault-button`. This function will be called when the PayPal button
        // is set up and ready to be used

        //默认是选中签约
        //_this.paypalGaVaultBi && _this.paypalGaVaultBi('expose_paypal_vaulting')
      }).catch(err => {
        _this.paypalGaBtn = false
        _this.paypalSdkLauching = false
        _this.loadingStatus(false)
        console.log('render paypal btn error', err)
        _this.recordPayLineErr('inline/paypal/initSdk', { msg: '按钮渲染失败', err })
        _this.sendErrorReport('paypal_render_btn_catch_error', {
          failure_type: 'api',
          client_url: '/third/sdk/error',
          description: `Paypal 按钮渲染失败: ${String(err?.message || err?.stack || '').substring(0, 100)}`,
        })
        clearTimeout(paypalSdkTimeout)
      })
    },

    //根据渠道调用: this[payInlineChannel](this.status.cardPay)
    klarna: function (payment_method, container = '', id = '') {
      let _this = this
      let paymentMethod = payment_method || this.status.cardPay
      if (window.KlarnaSDKIsLoad && this.payInlineSessionInfo[paymentMethod]) {
        try {
          Klarna.Payments.init({
            client_token: this.payInlineSessionInfo[paymentMethod].clientToken
          })
          Klarna.Payments.load({
            container: container.length > 0 ? container : `#${paymentMethod}-payments-container`,
            payment_method_category: this.payInlineSessionInfo[paymentMethod].paymentMethodCategory
          }, (res) => {
            this.showWidgetForm = res.show_form ? 2 : 0
            if (!res.show_form) {
              this.recordPayLineErr('inline/klarna/start', res)
            }
            if (container.length > 0 ) {
              setTimeout(() => {
                const element = document.querySelector(`.j-item-right-${id}`)
                if (element) {
                  element.style.verticalAlign = 'top'
                }
              })
            }
          })
        } catch (e) {
          _this.showWidgetForm = 0
          this.recordPayLineErr('inline/klarna/start', { err: e })
        }
      }
    },
    //根据渠道调用: _this[`${payInlineChannel}LoadWidget`]
    klarnaLoadWidget: async function () {
      if (!this.klarnaLoadWidgetSwitch) {
        return
      }
      let payment_code_unique = this.caculateData.payment_code_unique
      let amount = this?.checkout?.mall_caculate_info?.grandTotalPrice?.amount || this?.checkout?.results?.carts?.data?.totalPrice?.amount
      let country = this?.checkout?.default_address?.value || ''

      if (!country) {
        const countryData = await UserInfoManager.get({ key: 'Country', actionType: '/checkout/klarnaInlineMixin_klarnaLoadWidget' })
        country = countryData?.countryAbbr || ''
      }

      if (!(amount && country) || this.showWidgetForm < 2) {
        return
      }

      if (window.KlarnaSDKIsLoad && this.payInlineSessionInfo[payment_code_unique]) {
        let purchaseData = this.payInlineSessionInfo[payment_code_unique].purchaseData
        let amountFixed = amount.toString().split('.')[1].length
        let orderAmount = Number((Number(amount) * Math.pow(10, amountFixed)).toFixed(0))
        let newPurchaseData = {
          'purchase_country': purchaseData.purchase_country,
          'purchase_currency': purchaseData.purchase_currency,
          'locale': purchaseData.locale,
          'order_amount': orderAmount,
          'order_tax_amount': 0,
          'order_lines': [{
            'type': 'physical',
            'reference': 'checkout-reference-' + Date.now(),
            'name': 'checkout-name-' + Date.now(),
            'quantity': 1,
            'unit_price': orderAmount,
            'tax_rate': 0,
            'total_amount': orderAmount,
            'total_discount_amount': 0,
            'total_tax_amount': 0
          }]
        }

        Klarna.Payments.load({
          container: `#${payment_code_unique}-payments-container`,
          payment_method_category: this.payInlineSessionInfo[payment_code_unique].paymentMethodCategory
        }, newPurchaseData, function (res) {
          console.log('info:' + res)
        })
      }

    },
    initPayInlineSession: function ({ paymentMethods = [], fromAgain = false, cb, justReq = false }) {
      let abtMethods = [], needPayInlinePaymentMethods = []
      this.payInlineAbtInfo = {
        ...(this.payInlineAbtInfo || {}),
        paypal: [ ...(this.payInlineAbtInfo?.paypal || []), ...this.defaultInlineMethods ]
      }
      Object.keys(this.payInlineAbtInfo).forEach(key => {
        abtMethods = abtMethods.concat(this.payInlineAbtInfo[key])
      })
      if (abtMethods.length) {
        paymentMethods.forEach(item => {
          if (abtMethods.indexOf(item.code) > -1) {
            needPayInlinePaymentMethods.push(item.code)
          }
        })
      }
      this.needPayInlinePaymentMethods = needPayInlinePaymentMethods
      this.getPayInlineSession({ fromAgain, cb, justReq })

      //记录日志
      const payments = paymentMethods.map(item => item.code)
      this.recordPayLineErr('/need-payinline-methods', { needPayInlinePaymentMethods, payments })
    },
    getPayInlineSession: async function ({ fromAgain, cb, justReq }) {
      if (!this.needPayInlinePaymentMethods.length) return
      let amount = fromAgain
        ? this.checkout.order.totalPrice.amount
        : this?.checkout?.mall_caculate_info?.grandTotalPrice?.amount ||
          this?.checkout?.results?.carts?.data?.totalPrice?.amount
      let orderCurrency = fromAgain
        ? this.checkout.order.currency_code
        : this.currencyCode

      let country = fromAgain
        ? this.checkout.order.shipping_country_code
        : this?.checkout?.default_address?.value ||
          ''
		  if (!country) {
        const countryData = await UserInfoManager.get({ key: 'Country', actionType: '/checkout/klarnaInlineMixin_getPayInlineSession' })
        country = countryData?.countryAbbr || ''
      }

      //礼品卡下单页
      if( this.nowPageType == 3 ){
        amount = this.cardInfo?.sale_price_fromat_price?.amount
        country = this.checkout?.default_address?.value
        orderCurrency = this.currency

        //礼品卡二次下单页
      } else if( this.nowPageType == 4 ){
        amount = this.checkout?.order?.currency_total_all
        country = this.checkout?.order?.countryCode
        orderCurrency = this.checkout?.order?.currency_code
      } else if (this.nowPageType == 6 || ['Xtra'].includes(this.nowCheckoutScene)) {
        // 单独购
        amount = this.primePriceInfo?.paid_total?.amount
        country = this.checkout?.default_address?.value
        orderCurrency = this.currency
      }

      if (!(amount && country)) {
        !country && this.sendErrorReport('paypal_countryValue_empty', {
          failure_type: 'api',
          client_url: '/api/checkout/channelSession/create',
          description: 'Paypal获取 session 国家信息为空',
        })

        !amount && this.sendErrorReport('paypal_amount_empty', {
          failure_type: 'api',
          client_url: '/api/checkout/channelSession/create',
          description: 'Paypal获取 session 金额信息为空',
        })
        return
      }
      let data = {
        shipCountry: country,
        orderAmount: '' + amount,
        orderCurrency: orderCurrency,
        // businessModel: this.hasStoreGoods ? 1 : 0,
        payMethodList: this.needPayInlinePaymentMethods
      }
      let orderDetailsCacheVal = ''
      if (this.createSessionOrderDetails?.length) {
        data.orderDetails = this.createSessionOrderDetails
        orderDetailsCacheVal = data.orderDetails?.map?.(v => `${v.businessModel}:${v.companyId}`).join('_') || ''
      }

      let cacheKey = data.shipCountry + '_' + data.orderAmount + '_' + data.orderCurrency + '_' + data.payMethodList.join('_') + '_' + orderDetailsCacheVal

      this.nowInlineCacheKey = cacheKey
      this.showWidgetForm = 1

      //如果有缓存，则直接执行LoadWidget
      if (this.payInlineSessionInfoCache[cacheKey]) {
        this.payInlineSessionInfo = this.payInlineSessionInfoCache[cacheKey]

        let payInlineChannel = this.judgeNeedPayInlineChannel(this.status.cardPay)
        this.processList.push('channelToken-' + payInlineChannel)
        if (this.status.cardPay !== 'PayPal-Venmo') {
          this.paypalVenmoBtnShow = false
        }
        if (payInlineChannel) {
          this[payInlineChannel] && this[payInlineChannel](this.status.cardPay)
        }
        cb && cb()
      } else {
        //justReq, 只请求生成缓存，不做任何逻辑操作
        if( !justReq ){
          //没缓存的话，需要冲洗初始化sdk并执行LoadWidget
          //this.loadingStatus(true)
          //this.sdkTokenReqing = true
        }
        schttp({
          method: 'POST',
          url: '/api/checkout/channelSession/create',
          data: data,
        })
          .then((res) => {
            let returnStatus = 'sessionapi_return_clientToken'
            if (res.code != 0) {
              returnStatus = 'sessionapi_not_response'
            }
            if (res.code == 0 && !res?.info?.sessions?.length) {
              returnStatus = 'sessionapi_return_failure'
            }

            // 创建session接口返回结果曝光
            daEventCenter?.triggerNotice?.({
              daId: '2-31-6',
              extraData: {
                failure_msg: res?.code || '',
                payment_method: 'PayPal-GApaypal',
                return_status: returnStatus
              }
            })
            if (!res?.info?.sessions?.length) {
              this.recordPayLineErr('post-channel-token', { res, data })
            }
            let failChannel = []
            if (res.code == 0) {
              let sessions = res?.info?.sessions || []
              if (!sessions.length) {
                data.payMethodList.forEach(key => {
                  Object.keys(this.payInlineAbtInfo).forEach(channel => {
                    if( this.payInlineAbtInfo[channel].includes(key) ){
                      failChannel.push(channel)
                    }
                  })
                })

                this.recordPayLineErr('/api/checkout/channelSession/create/' + (failChannel.join(',')), res)
                return
              }
              let payInlineSessionInfo = {}
              sessions.forEach(item => {
                payInlineSessionInfo[item.payMethod] = item
              })
              this.payInlineSessionInfo = payInlineSessionInfo
              this.payInlineSessionInfoCache[cacheKey] = payInlineSessionInfo

              if( justReq ){
                return
              }

              // 如果选中方式需要直连则需要初始化sdk
              let payInlineChannel = this.judgeNeedPayInlineChannel(this.status.cardPay)
              this.processList.push('channelToken-' + payInlineChannel)
              if (payInlineChannel) {
                this[payInlineChannel] && this[payInlineChannel](this.status.cardPay)
                cb && cb()
              } else {
                this.paypalGaBtn = false
              }

              //对比传参的支付code，筛选出没返回token的支付code的渠道
              data.payMethodList.forEach(key => {
                if( !Object.keys(payInlineSessionInfo).includes(key) ){
                  Object.keys(this.payInlineAbtInfo).forEach(channel => {
                    if( this.payInlineAbtInfo[channel].includes(key) ){
                      failChannel.push(channel)
                    }
                  })
                }
              })


              if (failChannel.length) {
                this.recordPayLineErr('/api/checkout/channelSession/create/' + (failChannel.join(',')), res)
              }
            } else {
              this.paypalGaBtn = false
              this.recordPayLineErr('/api/checkout/channelSession/create', res)

			        this.sendErrorReport('create_session_res_error', {
                failure_type: 'api',
                client_url: '/api/checkout/channelSession/create',
                description: '创建渠道Session返回状态异常',
                status_code: res?.code || ''
              })
            }
          })
          .catch((err) => {
            //如果最新token更新失败，则paypal-ga走兜底
            this.paypalGaBtn = false
            this.loadingStatus(false)
            this.recordPayLineErr('/channel-token-error', err)
            // 创建session接口返回结果曝光
            daEventCenter?.triggerNotice?.({
              daId: '2-31-6',
              extraData: {
                failure_msg: err?.message || '',
                payment_method: 'PayPal-GApaypal',
                return_status: 'sessionapi_not_response'
              }
            })

            this.sendErrorReport('create_session_catch_error', {
              failure_type: 'api',
              client_url: '/api/checkout/channelSession/create',
              description: `创建渠道Session失败，${err?.message || ''}`,
            })
          })
      }
    },
		reInitSdk(){
			// 如果选中方式需要直连则需要初始化sdk
			let payInlineChannel = this.judgeNeedPayInlineChannel(this.status?.cardPay)
			if (payInlineChannel) {
				this[payInlineChannel] && this[payInlineChannel](this.status?.cardPay)
			}
		},
    recordPayLineErr: function (channelPath = '', err, extraData = {}) {
      apiReport.report({
        apiPath: channelPath,
        siteuid: gbCommonInfo?.SiteUID,
        nowPageType: this.nowPageType,
        nowCheckoutScene: this.nowCheckoutScene,
        err: JSON.stringify(err),
        ...(extraData || {})
      })
    },
    ppBraintreeErrorReport ({
      apiScene = '',
      error = {},
      payment_method = '',
    }) {
      const message = `scene: ${apiScene}, error: ${error?.message || ''}`
      const originError = error?.name == 'BraintreeError' ? (JSON.stringify(error).substring(0, 600)) : ''
      this.recordPayLineErr('paypal/braintree-error', message, { originError, payment_method })
    },
    showPaypalGaType(isShow, isPayListDailog = false){
      this.paypalGaVault.tipShow = isShow
      this.paypalGaVault.radioType = this.paypalGaVault.type
      this.paypalGaVault.isPayListDailog = isPayListDailog
      isShow && this.paypalGaVaultBi && this.paypalGaVaultBi('click_paypalvaulting_choose')
      if (isShow) {
        // paypal签约弹窗曝光
        daEventCenter?.triggerNotice?.({
          daId: '2-31-7',
          extraData: {
            payment_method: 'PayPal-GApaypal'
          }
        })
      }
    },
    confirmPPGVType(){
      this.paypalGaVault.type = this.paypalGaVault.radioType
      this.paypalGaVault.tipShow = false
      // if( this.paypalGaVault.type == '1' ){
      // 	this.paypalGaVaultBi && this.paypalGaVaultBi('expose_paypal_vaulting')
      // }
      let type = ''
      const extraData = {}
      if( this.paypalGaVault.type == PPGA_CHECK_TYPE.CHECKED && this.ppgvAccountInfo?.signUpEmail ){
        type = 'vaulting'
      } else if( this.paypalGaVault.type == PPGA_CHECK_TYPE.CHECKED && !this.ppgvAccountInfo?.signUpEmail ){
        type = 'payandsave'
      } else if( this.paypalGaVault.type != PPGA_CHECK_TYPE.CHECKED && this.ppgvAccountInfo?.signUpEmail ){
        type = 'vaultingtoGA'
      } else if( this.paypalGaVault.type != PPGA_CHECK_TYPE.CHECKED && !this.ppgvAccountInfo?.signUpEmail ){
        type = 'novaultingtoGA'
      }
      extraData.type = type
      this.paypalGaVaultBi && this.paypalGaVaultBi('click_popup_vaulting_changeconfirm', extraData)
    },
    confirmAfterpayType(){
      this.afterpayVault.type = this.afterpayVault.radioType
    },
    togglePPGVType(newPaypalGaVault){
      console.log('newPaypalGaVault >>>', newPaypalGaVault)
      if (newPaypalGaVault) {
        this.paypalGaVault = newPaypalGaVault
      } else {
        this.paypalGaVault.radioType = this.paypalGaVault.radioType == PPGA_CHECK_TYPE.CHECKED ? PPGA_CHECK_TYPE.UNCHECKED : PPGA_CHECK_TYPE.CHECKED
      }
			
      this.confirmPPGVType()
    },
    toggleAfterpayType(newAfterpayVault){
      console.log('newAfterpayVault >>>', newAfterpayVault)
      if (newAfterpayVault) {
        this.afterpayVault = newAfterpayVault
      } else {
        this.afterpayVault.radioType = this.afterpayVault.radioType == AFTER_PAY_CHECK_TYPE.CHECKED ? AFTER_PAY_CHECK_TYPE.UNCHECKED : AFTER_PAY_CHECK_TYPE.CHECKED
      }
			
      this.confirmAfterpayType()
    },
    toggleSignupPayType(paymentCode, newSignupPayType) {
      console.log('newSignupPayType >>>', newSignupPayType)
      const paymentVault = this.signupPayVault[paymentCode]
      if (newSignupPayType) {
        this.signupPayVault[paymentCode] = newSignupPayType
        return
      } else {
        if (paymentVault) {
          paymentVault.radioType = paymentVault.radioType == SIGNUP_CHECK_TYPE.CHECKED ? SIGNUP_CHECK_TYPE.UNCHECKED : SIGNUP_CHECK_TYPE.CHECKED
        }
      }
      if (paymentVault) {
        paymentVault.type = paymentVault.radioType
      }
      this.signupPayVault[paymentCode] = paymentVault
    },
    initPayPalVenmo(
      clientToken = '',
      payment_method = 'PayPal-Venmo',
      profileId = ''
    ) {
      window.braintree.client
        .create({
          authorization: clientToken,
        })
        .then(function (clientInstance) {
          return Promise.all([
            window.braintree.dataCollector.create({
              client: clientInstance,
              paypal: true,
            }),
            window.braintree.venmo.create({
              client: clientInstance,
              allowDesktop: true,
              profileId: profileId,
              paymentMethodUsage: 'single_use', // available in v3.77.0+
            }),
          ])
        })
        .then((results) => {
          let dataCollectorInstance = results[0]
          let venmoInstance = results[1]
          this.paypalVenmoDeviceId = dataCollectorInstance.deviceData
          daEventCenter?.triggerNotice?.({
            daId: '2-31-5',
            extraData: {
              payment_method,
              return_status: 'sdkjsapi_return_success'
            }
          })
          if (!this.paypalVenmoDeviceId) {
            this.recordPayLineErr('inline/paypal/initSdk', {
              msg: payment_method + ' 未获取到risk data',
              dataCollectorInstance
            })
          }

          // At this point, you should access the deviceData value and provide it
          // to your server, e.g. by injecting it into your form as a hidden input
          console.log('Got device data:', dataCollectorInstance.deviceData)

          // Verify browser support before proceeding.
          if (!venmoInstance.isBrowserSupported()) {
            console.log('Browser does not support Venmo')
            this.recordPayLineErr('inline/paypal/initSdk', {
              msg: 'Browser does not support Venmo',
              payment_method
            })

            this.sendErrorReport('paypal_venmo_not_support_sdk', {
              failure_type: 'sdk',
              client_url: '/third/sdk/error',
              description: 'venmo SDK初始化失败',
              paymentActionType: 'venmo_sdk'
					  })

            return
          }

          this.displayVenmoButton(venmoInstance)

          // Check if tokenization results already exist. This occurs when your
          // checkout page is relaunched in a new tab. This step can be omitted
          // if allowNewBrowserTab is false.
          if (venmoInstance.hasTokenizationResult()) {
            this.venmoPay()
          }
        }).catch((err) => {
          this.paypalVenmoBtnShow = false
          this.loadingStatus(false)
          console.log('paypal venmo sdk error: ', err)
          this.recordPayLineErr('inline/paypal/initSdk', { msg: 'venmo初始化失败', payment_method, err })
          daEventCenter?.triggerNotice?.({
            daId: '2-31-5',
            extraData: {
              payment_method,
              return_status: 'sdkjsapi_return_failure'
            }
          })

          this.sendErrorReport('paypal_venmo_init_sdk_error', {
            failure_type: 'sdk',
            client_url: '/third/sdk/error',
            description: `venmo SDK初始化失败 ${err?.message || ''}`,
            paymentActionType: 'venmo_sdk'
			    })
          return null
        })

		},
		venmoPay() {
			if (this.venmoInstance) {
				this.venmoInstance.tokenize().then(this.handleVenmoSuccess).catch(this.handleVenmoError)
			}
		},
		displayVenmoButton(venmoInstance = null) {
			this.venmoInstance = venmoInstance
			this.paypalVenmoBtnShow = true
			this.loadingStatus(false)
		},
		handleVenmoError(err) {
			const { host } = typeof window === 'undefined' ? {} : gbCommonInfo || {}
			const giftCardPath = location.pathname.indexOf('/giftcard') > -1 ? '/giftcard' : ''
			let msg = ''
			// console.log('venmo error: ', err?.code)
			if (err.code === 'VENMO_CANCELED') {
				msg = 'App is not available or user aborted payment flow'
			} else if (err.code === 'VENMO_APP_CANCELED' || err?.code === 'VENMO_DESKTOP_CANCELED') {
				msg = 'User canceled payment flow'
			} else {
				msg = err.message
			}
			this.recordPayLineErr('inline/paypal/initSdk', { msg: `venmo支付失败:${msg}`, payment_method: 'PayPal-Venmo' })
			this.ppBraintreeErrorReport({
				apiScene: 'venmoError',
				error: err,
				payment_method: 'PayPal-Venmo'
			})
			this.sendErrorReport('paypal_venmo_sdk_content_error', {
				failure_type: 'sdk',
				client_url: '/third/sdk/error',
				description: 'PayPal-Venmo获取SDK对应nonce失败 ' + msg,
				paymentActionType: 'venmo_sdk'
			})

      if (err.code?.indexOf('CANCELED') === -1) {
        // 曝光请求渠道SDK获取nonce值结果
        daEventCenter?.triggerNotice?.({
          daId: '2-31-4',
          extraData: {
            payment_method: 'PayPal-Venmo',
            return_status: 'nonceapi_return_failure'
          }
        })

        this.sendErrorReport('paypal_venmo_get_nonce_error', {
          failure_type: 'sdk',
          client_url: '/third/sdk/error',
          description: 'PayPal-Venmo获取SDK对应nonce失败',
          paymentActionType: 'venmo_sdk'
        })
      } else {
        // 取消/关闭弹窗点击
        daEventCenter?.triggerNotice?.({
          daId: '2-31-2',
          extraData: {
            payment_method: 'PayPal-Venmo'
          }
        })
      }
      let order = window.orderAgainVue ? this.checkout.order : this.orderDate
      if ([1, 3].includes(this.nowPageType) && order.billno) {
        var checkout_againurl = host + giftCardPath + '/checkout/checkout_again/' + order.billno
        GB_route_jump(checkout_againurl)
      } else if ([2, 4].includes(this.nowPageType) || ['XtraAgain', 'BuyPrimeAgain'].includes(this.nowCheckoutScene)) {
        this.status.checkout = 0
      } else if (['Xtra'].includes(this.nowCheckoutScene)) {
        routerReplace(host + '/xtra_again/' + order.billno)
      } else if (this.nowPageType == 6) {
        routerReplace(host + '/buyprime_again/' + order.billno)
      }
    },
    handleVenmoSuccess(payload) {
      this.status.checkout = false

      let order = window.orderAgainVue ? this.checkout.order : this.orderDate
      let nonceReturnStatus = 'nonceapi_return_success'
      if (!payload?.nonce) {
        nonceReturnStatus = 'nonceapi_return_failure'
      }
      // 曝光请求渠道SDK获取nonce值结果
      daEventCenter?.triggerNotice?.({
        daId: '2-31-4',
        extraData: {
          payment_method: 'PayPal-Venmo',
          return_status: nonceReturnStatus
        }
      })
      if (!payload?.nonce) {
        this.ppBraintreeErrorReport({
          apiScene: 'venmoNonceNull',
          error: { message: 'PayPal-Venmo nonce is null' },
          payment_method: 'PayPal-Venmo'
        })
      }

      this.loadingStatus(true)
      S_Module.require('checkout').paypalGaPay({
        order,
        vm: this,
        payload,
        type: this.checkoutType,
        checkoutScene: this.nowCheckoutScene
      })
    },

    getProductType() {
      const typeObj = {
        GiftCard: 'gift_card',
        GiftCardAgain: 'gift_card',
        BuyPrime: 'paid_membership',
        Xtra: 'paid_shein_saver',
        XtraDetail: 'paid_shein_saver',
      }

      if (Object.keys(typeObj).includes(this.nowCheckoutScene)) {
        return typeObj[this.nowCheckoutScene]
      }

      return 'normal'
    },

    sendErrorReport (payment_error_scene = '', params = {
      failure_type: '',
      client_url: '',
      status_code: '',
      description: '',
      paymentCode: '',
      paymentActionType: 'paypal_sdk'
    }) {
      errorReport.sendPaymentError(payment_error_scene, {
        failure_type: params?.failure_type || '',
        product_type: this.getProductType(),
        payment_action_type: params?.paymentActionType,
        client_url: params?.client_url || '',
        error_data: {
          status_code: params?.status_code,
          uorder_id: this.orderInfo?.relation_billno,
          order_no: this.orderInfo?.billno,
          payment_method: params?.paymentCode,
          description: params?.description || '',
        }
      })
    },
    initKlarnaInlineSdk () {
      if (Object.keys(this.payInlineAbtInfo || {})?.includes?.('klarna')) {
        const self = this
        window.klarnaAsyncCallback = function () {
          window.KlarnaSDKIsLoad = true
          self.reInitSdk?.()
        }
        window.TPM?.run?.({
          marketing: 'InitChannelSdk',
          method: '_initKlarnaSdk'
        })
        // klarnaLoadWidget开关
        schttp({
          method: 'POST',
          url: '/api/config/apollo/get',
          data: { apolloKeys: 'RELOAD_KLARNAL_WIDGET_SWITCH' }
        }).then(res => {
          this.klarnaLoadWidgetSwitch = res?.info?.RELOAD_KLARNAL_WIDGET_SWITCH === 'on'
        })
      }
    }
  },
}
